import { columnsDataComplex } from "./variables/columnData";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { FaUserPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import { getAllPlansInAdmin } from "../../../axios/admin/plans/getAllPlansInAdmin";
import GetAllPlans from './components/GetAllPlans/GetAllPlans'
import AddPlan from "./components/AddPlan";
import { getAllPlanErrors } from "errors/error";

export const Plan = () => {
  const [planData, setPlanData] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async () => {
    const response = await getAllPlansInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'));
    try {
      if (response && response.statusText === "OK") {
        console.log("In response")
        setError("")
        setPlanData(response.data);
        localStorage.setItem('plan_data', JSON.stringify(response.data));
      }
    } catch (err) {
      if (!err?.response) {
        setError("No Server Response, check your console for more details")
      } else if (getAllPlanErrors[err.response?.status]) {
        setError(getAllPlanErrors[err.response.status]);
      } else {
        setError('Failed')
      }
    }
  };

  useEffect(() => {
    const storedPlanData = localStorage.getItem('plan_data');
    if (storedPlanData) {
      setPlanData(JSON.parse(storedPlanData));
    } else {
      fetchData();
    }
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 lg:col-span-12 lg:mb-0">
        <GetAllPlans
          columnsData={columnsDataComplex}
          tableData={planData}
          error={error}
          fetchData={fetchData}
        />
      </div>

      <FixedPlugin
        buttonIcon={<FaUserPlus className="h-7 w-7 text-white" />}
        modalComponent={<Modal component={<AddPlan fetchData={fetchData} />} />}
      />
    </div>
  );
};

export default Plan;
