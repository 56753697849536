import React, { useState } from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { IoCard } from "react-icons/io5";
import Address from './Address';
import BankDetails from './BankDetails';

const Tab = ({customerDetails}) => {
  const [activeTab, setActiveTab] = useState('Address');

  const tabs = [
    { name: 'Address', icon: IoLocationSharp },
    { name: 'Bank Details', icon: IoCard }
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'Address':
        return <Address customerDetails={customerDetails}/>;
      case 'Bank Details':
        return <BankDetails customerDetails={customerDetails}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          {tabs.map((tab, index) => (
            <li key={index} className="me-2">
              <button
                onClick={() => setActiveTab(tab.name)}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group
                  ${activeTab === tab.name ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`}
              >
                {tab.icon && <tab.icon className={`w-4 h-4 me-2 ${activeTab === tab.name ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'}`} aria-hidden="true" />}
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default Tab;


