import React from "react";

const BankDetails = ({customerDetails}) => {
  return (
    <div className="rounded-lg bg-white bg-clip-border p-4 dark:!bg-navy-800 dark:text-white">
      <h2 className="mb-4 text-xl font-semibold text-gray-800 dark:text-white">
        Bank Details
      </h2>
      <div>
        <p>Bank Name : {customerDetails.bank_name !== null ? customerDetails.bank_name : 'NO DETAILS'}</p>
        <p>Account Holder : {customerDetails.bank_account_holder !== null ? customerDetails.bank_account_holder : 'NO DETAILS'}</p>
      </div>
      <div>
        <p>IFSC Code : {customerDetails.bank_ifsc !== null ? customerDetails.bank_ifsc : 'NO DETAILS'}</p>
      </div>
      <div>
        <p>Account Number : {customerDetails.bank_account !== null ? customerDetails.bank_account : 'NO DETAILS'}</p>
      </div>
    </div>
  );
};

export default BankDetails;
