const paymentsColumn = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "CUSTOMER ID",
        accessor: "customer_id",
    },
    {
        Header: "DATE OF INSTRUMENT",
        accessor: "date_of_instrument",
    },
    {
        Header: "AMOUNT",
        accessor: "amount",
    },
    {
        Header: "RECEIVING ACCOUNT",
        accessor: "receiving_account",
    },
    {
        Header: "RECEIVING BANK",
        accessor: "receiving_bank",
    },
    {
        Header: "REFERENCE ID",
        accessor: "reference_id",
    },
    {
        Header: "SUBSCRIPTION ID",
        accessor: "subscription_id",
    },
    {
        Header: "INVOICE ID",
        accessor: "invoice_id",
    },
    {
        Header: "NOTES",
        accessor: "notes",
    },
    {
        Header: "ADMIN USER",
        accessor: "admin_user",
    },
    {
        Header: "ACTION",
        accessor: "action",
    },
];

const subscriptionColumn = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "CUSTOMER ID",
        accessor: "customer_id",
    },
    {
        Header: "PLAN ID",
        accessor: "plan_id",
    },
    {
        Header: "START DATE",
        accessor: "start_date",
    },
    {
        Header: "END DATE",
        accessor: "end_date",
    },
    {
        Header: "PRICE",
        accessor: "price",
    },
    {
        Header: "DISCOUNT PERCENT",
        accessor: "discount_percent",
    },
    {
        Header: "ONE TIME CONSESSION",
        accessor: "one_time_consession",
    },
    {
        Header: "ONE TIME FEE",
        accessor: "one_time_fee",
    },
    {
        Header: "SOLD BY",
        accessor: "sold_by",
    },
    {
        Header: "MANAGED BY",
        accessor: "managed_by",
    },
    {
        Header: "NOTES",
        accessor: "notes",
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
    },
    {
        Header: "ACTION",
        accessor: "action",
    },
];

export { paymentsColumn, subscriptionColumn }