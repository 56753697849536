import React from 'react'
import { IoMdClose } from "react-icons/io";

const Modal = ({ component, handleModalClose }) => {

    return (
        <div className='!z-9 relative flex flex-col rounded-[20px] p-5 mx-8 bg-clip-border bg-white  dark:!bg-navy-800 text-navy-700 dark:text-white dark:shadow-none '>
            <div className='flex justify-end cursor-pointer'>
                <IoMdClose className='w-[30px] h-[30px]' onClick={handleModalClose} />
            </div>
            <div>
                {React.cloneElement(component, { handleModalClose })}
            </div>
        </div>
    )
}

export default Modal