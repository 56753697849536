import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { useMemo } from "react";
import Modal from "components/modal/Modal";
import TooltipHorizon from "components/tooltip";
import DeletePlanFeature from "../Actions/DeletePlanFeature";
import DeletePlanFeatureComponent from "../DeletePlanFeatureComponent";

const ComplexTable = (props) => {
    const { columnsData, tableData, error, fetchData } = props;
    console.log(error);
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 }, // Initial page size (number of rows per page)
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex, pageSize },
    } = tableInstance;

    return (
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Plan Features List
                </div>
                <CardMenu />
            </div>

            <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                                    >
                                        <p className="text-xs tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </p>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "ID") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "NAME") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "PLAN ID") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "CHAIN ID") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "TPS") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "NOTES") {
                                            data = (
                                                    cell.value !== null && cell.value !== "" ? (
                                                        <TooltipHorizon
                                                            content={cell.value}
                                                            trigger={
                                                                <div className="cursor-pointer">
                                                                    <p>{cell.value?.slice(0, 15)}...</p>
                                                                </div>
                                                            }
                                                            placement="top"
                                                        />
                                                    ) : (
                                                        <p>null</p>
                                                    )
                                            );
                                        } else if (cell.column.Header === "ACTIONS") {
                                            data = (
                                                <div className="mx-auto flex flex-row gap-x-[15px] text-xl">
                                                    <div className="cursor-pointer text-red-500 hover:text-gray-700">
                                                        <DeletePlanFeature
                                                            modalComponent={
                                                                <Modal
                                                                    component={
                                                                        <DeletePlanFeatureComponent
                                                                            pf={row.original}
                                                                            fetchData={fetchData}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    {/* <div className="cursor-pointer text-blue-600 hover:text-gray-700">
                                                        <ViewPlan
                                                            modalComponent={
                                                                <Modal
                                                                    component={
                                                                        <UpdatePlan
                                                                            plan={row.original}
                                                                            fetchData={fetchData}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </div> */}
                                                </div>
                                            );
                                        }

                                        return (
                                            <td
                                                className="pt-[14px] pb-[18px] sm:text-[14px]"
                                                {...cell.getCellProps()}
                                                key={index}
                                            >
                                                {data}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {error && <p className="my-[15px] text-center text-red-600">{error}</p>}
            </div>
            {/* Pagination controls */}
            <div className="mt-4 flex justify-end gap-3">
                <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="linear mt-2 rounded-md bg-brand-500 py-2 px-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                    Previous
                </button>
                <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="linear mt-2 rounded-md bg-brand-500 py-2 px-8 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                    Next
                </button>
                <span className="mx-2 my-auto text-gray-600">
                    Page {pageIndex + 1} of {pageOptions.length}
                </span>
            </div>
        </Card>
    );
};

export default ComplexTable;
