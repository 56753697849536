import axios from "axios";
import { BASE_URL } from "./index";

export const getSubscriptionForCustomer = (cid, x_token, x_user) => axios.post(`${BASE_URL}/get_all_subscriptions_for_customer?cid=${cid}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const addSubscriptionForCustomer = (cid, x_token, x_user, { plan_id, start_date, end_date, currency, price, discount_percent, one_time_concession, one_time_fee, sold_by, managed_by, notes }) => axios.post(`${BASE_URL}/add_subscription?cid=${cid}`,
    { plan_id, start_date, end_date, currency, price, discount_percent, one_time_concession, one_time_fee, sold_by, managed_by, notes },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const getAllSubscriptions = (x_token, x_user) => axios.post(`${BASE_URL}/get_all_subscriptions`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const updateSubscription = (cid, sid, x_token, x_user, new_end_datetime) => axios.post(`${BASE_URL}/update_subscription_end_date?sub_id=${sid}&cid=${cid}&new_end_datetime=${new_end_datetime}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const deleteSubscription = (cid, sid, x_token, x_user) => axios.post(`${BASE_URL}/delete_subscription?sub_id=${sid}&cid=${cid}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)