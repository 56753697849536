import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/features/authSlice";
import { verifyOtp } from "../../axios/auth/verifyOtp";

const OtpBox = ({ loginRes, email }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpComplete, steOtpComplete] = useState(false)
  const otpBoxes = useRef([]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(30);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer((prev) => prev - 1);
      } else {
        setResendDisabled(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [resendTimer]);

  useEffect(() => {
    const otpValue = otp.join("")
    console.log("Otp change")
    if(otpValue.length == 6 && otp.every(digit => digit != "")) {
      steOtpComplete(true)
    }
  }, [otp])

  const handleResend = () => {
    setResendDisabled(true);
    setResendTimer(30);
    // Logic to resend OTP
  };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < otp.length - 1) {
      otpBoxes.current[index + 1].focus();
    }
  };

  const handleKeyUp = (index, event, value) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      otpBoxes.current[index - 1].focus();
      otpBoxes.current[index - 1].select();
    }
    handleChange(index, value);
  };

  const handleVerifyOtp = async () => {
    const otpValue = otp.join("");
  
    try {
      const response = await verifyOtp(otpValue, loginRes.x_user, loginRes.x_token);
      console.log("response in otpBox", response);
      if (response.data.status === '200') {
        sessionStorage.setItem('x-user', response.data.x_user);
        sessionStorage.setItem('x-token', response.data.x_token);
        dispatch(login({ xToken: response.data.x_token, xUser: response.data.x_user}));
        navigate('/admin/default');
      } else if(response.data.status === '422') {
        setError("Validation Error");
      } else {
        setError("An error occured, try signing in again")
      }
    } catch (error) {
      setError(error.message);
    }
  };
  

  return (
    <div className="flex flex-col gap-y-4 max-w-full md:max-w-[400px] rounded-md my-[50px] ">
      <div className="flex items-center dark:text-white">
        <span>An OTP has been sent to <span className="text-blue-600">{email}</span></span>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center dark:text-white">
          <span>Enter your OTP</span>
        </div>
        <div className="flex space-x-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (otpBoxes.current[index] = el)}
              type="text"
              maxLength="1"
              className="w-[48px] h-12 text-2xl text-center border rounded border-gray-300 focus:outline-none focus:border-blue-400 max-[400px]:w-[30px]"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyUp={(e) => handleKeyUp(index, e, e.target.value)}
            />
          ))}
        </div>
        <div className="flex flex-col gap-y-[15px]">
          <p className="dark:text-white">
            Resend OTP in {resendTimer} {resendTimer === 1 ? "second" : "seconds"}
            <button
              onClick={handleResend}
              disabled={resendDisabled}
              className="text-blue-500 underline focus:outline-none pl-[10px]"
            >
              Resend
            </button>
          </p>
          {error !== '' && (
            <div className="text-red-600 text-center">
              <span>{error}</span>
            </div>
          )}
          <button
            className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${otpComplete ? 'pointer-events-auto' : 'pointer-events-none'}`}
            onClick={handleVerifyOtp}
          >
            Verify OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpBox;
