const states = [
    {
      "value": "",
      "label": "Select an option"
    },
    {
      "value": "andhra-pradesh",
      "label": "ANDHRA PRADESH"
    },
    {
      "value": "arunachal-pradesh-",
      "label": "ARUNACHAL PRADESH "
    },
    {
      "value": "assam",
      "label": "ASSAM"
    },
    {
      "value": "bihar",
      "label": "BIHAR"
    },
    {
      "value": "chhattisgarh",
      "label": "CHHATTISGARH"
    },
    {
      "value": "goa",
      "label": "GOA"
    },
    {
      "value": "gujarat",
      "label": "GUJARAT"
    },
    {
      "value": "haryana",
      "label": "HARYANA"
    },
    {
      "value": "himachal-pradesh",
      "label": "HIMACHAL PRADESH"
    },
    {
      "value": "jammu-and-kashmir",
      "label": "JAMMU AND KASHMIR"
    },
    {
      "value": "jharkhand",
      "label": "JHARKHAND"
    },
    {
      "value": "karnataka",
      "label": "KARNATAKA"
    },
    {
      "value": "kerala",
      "label": "KERALA"
    },
    {
      "value": "madhya-pradesh",
      "label": "MADHYA PRADESH"
    },
    {
      "value": "maharashtra",
      "label": "MAHARASHTRA"
    },
    {
      "value": "manipur",
      "label": "MANIPUR"
    },
    {
      "value": "meghalaya",
      "label": "MEGHALAYA"
    },
    {
      "value": "mizoram",
      "label": "MIZORAM"
    },
    {
      "value": "nagaland",
      "label": "NAGALAND"
    },
    {
      "value": "odisha",
      "label": "ODISHA"
    },
    {
      "value": "odissa",
      "label": "ODISSA"
    },
    {
      "value": "punjab",
      "label": "PUNJAB"
    },
    {
      "value": "rajasthan",
      "label": "RAJASTHAN"
    },
    {
      "value": "sikkim",
      "label": "SIKKIM"
    },
    {
      "value": "tamil-nadu",
      "label": "TAMIL NADU"
    },
    {
      "value": "telangana",
      "label": "TELANGANA"
    },
    {
      "value": "tripura",
      "label": "TRIPURA"
    },
    {
      "value": "uttar-pradesh",
      "label": "UTTAR PRADESH"
    },
    {
      "value": "uttarakhand",
      "label": "UTTARAKHAND"
    },
    {
      "value": "west-bengal",
      "label": "WEST BENGAL"
    },
    {
      "value": "andaman-and-nicobar-islands",
      "label": "ANDAMAN AND NICOBAR ISLANDS"
    },
    {
      "value": "chandigarh",
      "label": "CHANDIGARH"
    },
    {
      "value": "dadra-and-nagar-haveli",
      "label": "DADRA AND NAGAR HAVELI"
    },
    {
      "value": "daman-and-diu",
      "label": "DAMAN AND DIU"
    },
    {
      "value": "lakshadweep",
      "label": "LAKSHADWEEP"
    },
    {
      "value": "national-capital-territory-of-delhi",
      "label": "NATIONAL CAPITAL TERRITORY OF DELHI"
    },
    {
      "value": "delhi",
      "label": "DELHI"
    },
    {
      "value": "puducherry",
      "label": "PUDUCHERRY"
    },
    {
      "value": "pondicherry",
      "label": "PONDICHERRY"
    }
  ];
  
export default states;