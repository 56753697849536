import { getAllChain } from "../../../api/index"

const getAllChainInAdmin = async (x_token, x_user) => {
  try {
    return await getAllChain(x_token, x_user)
  } catch (error) {
    throw error
  }
}

export default getAllChainInAdmin