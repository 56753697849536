import { getAllPayments } from "../../../api/payments"

const getAllPaymentsInAdmin = async (x_token, x_user) => {
  try {
    return await getAllPayments(x_token, x_user)
  } catch (error) {
    console.log(error.message)
  }
}

export default getAllPaymentsInAdmin