import { getAllSubscriptions } from "../../../api/subscription"


const getAllSubscriptionsInAdmin = async (x_token, x_user) => {
  try {
    return await getAllSubscriptions(x_token, x_user)
  } catch (error) {
    console.log(error.message)
  }
}

export default getAllSubscriptionsInAdmin