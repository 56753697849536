import React, { useEffect, useState } from 'react'
import Card from 'components/card'
import deletePaymentInAdmin from '../../../../axios/admin/payments/deletePaymentInAdmin'

const DeletePaymentComponent = ({ payment, fetchData, handleModalClose }) => {
    const [feedbackMessage, setFeedbackMesage] = useState("")
    const [error, setError] = useState("")
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)

    useEffect(() => {
        const fetchDataFromSession = () => {
            setXToken(sessionStorage.getItem('x-token'))
            setXUser(sessionStorage.getItem('x-user'))
        }
        fetchDataFromSession()
    }, [])

    const handleDelete = async (payment_id) => {
        setFeedbackMesage("Waiting for response...")
        try {
            const response = await deletePaymentInAdmin(payment_id, x_token, x_user)
            
            /* ERROR: Internal server error on API */
            
            if (response.data === 'SUCCESS') {
                setError("")
                setFeedbackMesage("Payment deleted successfully")
                setTimeout(() => {
                    setFeedbackMesage("")
                    handleModalClose()
                    fetchData()
                }, 1000)
            } else {
                setError("Some error occured, try again later")
            }

        } catch (error) {
            setError(error.message)
        }
    }
    return (
        <Card extra={"items-center w-full h-full p-[16px] text-navy-700 bg-cover shadow-none max-w-[400px] fixed inset-0 flex items-center justify-center z-50"}>
            <div className='flex flex-col gap-y-[20px]'>
                <div>
                    <p>Are you sure you want to delete Payment with ID <span className='text-brand-600'>{payment.id}</span></p>
                </div>

                {feedbackMessage && (
                    <div
                        className="text-center text-sm text-green-500"
                        style={{ transition: "opacity 0.35s ease-in" }}
                    >
                        {feedbackMessage}
                    </div>
                )}

                {error && (
                    <div
                        className="text-center text-sm text-red-500"
                        style={{ transition: "opacity 0.35s ease-in" }}
                    >
                        {error}
                    </div>
                )}
                <button
                    className={`linear mt-2 w-full rounded-xl bg-red-500 py-[12px] text-base font-medium text-white transition duration-700 hover:bg-red-600 active:bg-red-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-600 dark:active:bg-red-700`}
                    type="button"
                    onClick={() => handleDelete(payment.id)}
                >
                    Delete
                </button>

            </div>


        </Card>
    )
}

export default DeletePaymentComponent