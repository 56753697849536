import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Card from "components/card";
import { useEffect } from "react";
import updateChainInAdmin from "../../../../axios/admin/chain/UpdateChainInAdmin";

export default function UpdateChain({ chain, handleModalClose, fetchData }) {
    const [formData, setFormData] = useState({
        name: chain.name || '',
        rpc_support: chain.rpc_support || '',
        network: chain.network || '',
        version: chain.version || ''
    });
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)
    const [chainId, setChainId] = useState(chain.id || null)
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setXToken(sessionStorage.getItem('x-token'))
        setXUser(sessionStorage.getItem('x-user'))
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const validateForm = () => {
        console.log("form data in function: ", formData)
        for (let key in formData) {
            if (formData[key] === "") {
                return false;
            }
        }
        console.log("form data in function1: ", formData)
        return true;
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        if (!validateForm()) {
            setError("All fields are required")
            return
        }

        setIsSubmitting(true);
        setFeedbackMessage("Waiting for response...");

        try {
            const response = await updateChainInAdmin(x_token, x_user, chainId, formData)
            console.log("Response of register customer: ", response)
            if (response && response.data === 'SUCCESS') {
                console.log("in response of get chain")
                setError("")
                setFeedbackMessage("Chain updated successfully")
                fetchData()
                setInterval(() => {
                    setFeedbackMessage("")
                    handleModalClose()
                }, 1000)
            } else {
                setError("An error occured, try again in some time")
            }
        } catch (error) {
            setError(error.message)
        } finally {
            setIsSubmitting(false)
        }
    };

    const handleDiscard = () => {
        setFormData({
            name: chain.name || '',
            rpc_support: chain.rpc_support || '',

            network: chain.network || '',
            version: chain.version || ''
        })

        handleModalClose();
    }

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Update Chain
                </h4>
            </div>

            <div className="flex-grow overflow-y-auto">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Chain id"
                        placeholder="chain id"
                        id="chain_id"
                        type="text"
                        value={chain.id}
                        disabled={true}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Name*"
                        placeholder="name"
                        id="name"
                        type="text"
                        value={formData.name}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="rpc_support*"
                        placeholder="12345"
                        id="rpc_support"
                        type="text"
                        value={formData.rpc_support}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Network*"
                        placeholder="network"
                        id="network"
                        type="text"
                        value={formData.network}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Version*"
                        placeholder="123456"
                        id="version"
                        type="text"
                        value={formData.version}
                        onChange={handleInputChange}
                    />
                </form>
            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0  pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Update
                </button>
                <button
                    className="linear px-6 rounded-xl bg-red-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-red-800 active:bg-red-700 dark:text-white "
                    type="button"
                    onClick={handleDiscard}
                >
                    Discard
                </button>

            </div>

            {feedbackMessage !== "" && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}

            {error !== "" && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}

        </Card>
    );
}
