// import { columnsDataComplex } from "./variables/columnData";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { FaUserPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import { paymentsColumn, subscriptionColumn } from "../customerDetails/variables/columnData";
import getAllSubscriptionsInAdmin from "../../../axios/admin/subscriptions/getAllSubscriptionsInAdmin";
import GetAllSubscriptions from "./components/GetAllSubscription/GetAllSubscriptions";

const Subscriptions = () => {
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async () => {
    const response = await getAllSubscriptionsInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'));
    try {
      console.log("Response is: ", response)
      if (response && response.statusText === "OK") {
        console.log("In response")
        setError("")
        setSubscriptionsData(response.data);
      } else {
        setError(response.status)
      }
    } catch (error) {
      setError(error.message)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 lg:col-span-12 lg:mb-0">
        <GetAllSubscriptions
          columnsData={subscriptionColumn}
          tableData={subscriptionsData}
          error={error}
          fetchData={fetchData}
        />
      </div>
{/* 
      <FixedPlugin
        buttonIcon={<FaUserPlus className="h-7 w-7 text-white" />}
        modalComponent={<Modal component={<CreateLogins />} />}
      /> */}
    </div>
  );
};

export default Subscriptions;
