import React, { useState, forwardRef } from "react";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";

const InputField = forwardRef((props, ref) => {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    name,
    value,
    showIcon,
    options,
    onClick
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <div className="flex items-center justify-center rounded-xl border">
        {type === "select" ? (
          <select
            disabled={disabled}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onClick={onClick}
            className={`grow bg-white/0 p-2 text-sm outline-none appearance-none dark:text-black ${
              disabled === true
                ? "!border-none dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
          >
            {options &&
              options.map((option, index) => (
                <option
                  key={index}
                  value={option.value}
                  className="dark:bg-white dark:text-black"
                >
                  {option.label}
                </option>
              ))}
          </select>
        ) : (
          <input
            disabled={disabled}
            type={showPassword ? "text" : type}
            id={id}
            placeholder={placeholder}
            className={`grow bg-white/0 p-2 text-sm outline-none ${
              disabled === true
                ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
            name={name}
            value={value}
            onChange={onChange}
            ref={ref}
            onClick={onClick}
          />
        )}
        {showIcon && type !== "select" && (
          <span
            className="my-auto ml-auto pr-3 cursor-pointer dark:text-white"
            onClick={handleShowPassword}
          >
            {showPassword ? <FaEyeSlash /> : <FaRegEye />}
          </span>
        )}
      </div>
    </div>
  );
});

export default InputField;
