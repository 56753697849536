import InputField from "components/fields/InputField";
import { hours, minutes, seconds } from '../variables/time';

const RenderTimeDropdown = (time, handleChange, type, label) => (
    <>
        <p className='text-sm text-navy-700 dark:text-white ml-3 font-bold'>{label}</p>
        <div className="flex gap-x-3">
            <InputField
                type="select"
                value={time.hour}
                extra={"m-0"}
                onChange={(e) => handleChange(e, type, 'hour')}
                options={hours}
            >
            </InputField>

            <InputField
                type="select"
                value={time.minute}
                onChange={(e) => handleChange(e, type, 'minute')}
                options={minutes}
            >
            </InputField>

            <InputField
                type="select"
                value={time.second}
                onChange={(e) => handleChange(e, type, 'second')}
                options={seconds}
            >
            </InputField>
        </div>
    </>
);

export default RenderTimeDropdown;