import Card from 'components/card';
import InputField from 'components/fields/InputField';
import React, { useEffect, useState } from 'react';
import addSubscriptionForCustomerInAdmin from '../../../../axios/admin/subscriptions/addSubscriptionForCustomerInAdmin';
import { useParams } from 'react-router-dom';
import { currencyOptions } from '../variables/currencyOptions';
import TextField from 'components/fields/TextField';
import fetchDetailsFromSession from 'utils/fetchDetailsFromSession';
import validateForm from 'utils/validFormData';
import { addSubscriptionsError } from 'errors/error';
import MiniCalendar from 'components/calendar/MiniCalendar';
import { getAllPlansInAdmin } from '../../../../axios/admin/plans/getAllPlansInAdmin';
import { getAllPlanErrors } from 'errors/error';
import RenderTimeDropdown from './RenderTimeDropdown';
import getAllAdminsInAdmin from '../../../../axios/admin/customer/getAllAdminsInAdmin';
import { clearConfigCache } from 'prettier';


const AddSubscription = ({ handleModalClose, fetchData }) => {
    const [error, setError] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [formData, setFormData] = useState({
        "plan_id": "",
        "start_date": new Date(),
        "end_date": new Date(),
        "currency": "",
        "price": "",
        "discount_percent": "",
        "one_time_concession": "",
        "one_time_fee": "",
        "sold_by": "",
        "managed_by": "",
        "notes": ""
    });
    const [startTime, setStartTime] = useState({ hour: 'HH', minute: 'MM', second: 'SS' });
    const [endTime, setEndTime] = useState({ hour: 'HH', minute: 'MM', second: 'SS' });

    const [x_token, setXToken] = useState(null);
    const [x_user, setXUser] = useState(null);
    const { cid } = useParams();

    // State for calendar visibility
    const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
    const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);

    // State for plan options
    const [planOptionsState, setPlanOptionsState] = useState([]);
    // State for manager and seller options
    const [managerSellerOptions, setManagerSellerOptions] = useState([]);

    // Fetching details from session
    useEffect(() => {
        const res = fetchDetailsFromSession();
        setXToken(res.x_token);
        setXUser(res.x_user);
    }, []);

    // Fetch plans from localStorage or API
    useEffect(() => {
        const storedPlanData = localStorage.getItem('plan_data');
        if (storedPlanData) {
            const parsedData = JSON.parse(storedPlanData);
            const options = parsedData.map(plan => ({ value: plan.id, label: `${plan.id}, ${plan.name}` }));
            setPlanOptionsState(options);
        } else {
            const fetchPlans = async () => {
                try {
                    const response = await getAllPlansInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'));
                    if (response && response.statusText === "OK") {
                        const options = response.data.map(plan => ({ value: plan.id, label: `${plan.id}, ${plan.name}` }));
                        setPlanOptionsState(options)
                        localStorage.setItem('plan_data', JSON.stringify(response.data));
                    }
                } catch (error) {
                    throw error
                }
            };

            try {
                fetchPlans();
            } catch (err) {
                if (!err?.response) {
                    setError("No Server Response, check your console for more details")
                } else if (getAllPlanErrors[err.response?.status]) {
                    setError(getAllPlanErrors[err.response.status]);
                } else {
                    setError('Failed')
                }
            }

        }
    }, []);

    useEffect(() => {
        const fetchManagerAndSeller = async () => {
            const response = await getAllAdminsInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'))
            console.log(response)
            if (response && response.statusText === "OK") {
                const options = response.data.map(data => ({ value: data.email, label: `${data.email}` }));
                setManagerSellerOptions(options)
                localStorage.setItem('manager_and_seller', JSON.stringify(response.data));
            }
        }

        try {
            const storedData = localStorage.getItem('manager_and_seller')
            if (storedData) {
                const parsedData = JSON.parse(storedData)
                const options = parsedData.map(data => ({ value: data.email, label: `${data.email}` }));
                setManagerSellerOptions(options)
            } else {
                fetchManagerAndSeller();
            }
        } catch (err) {
            console.log("err is: ", err)
            setError(err?.response)
        }
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleTimeChange = (e, type, timePart) => {
        const value = e.target.value;
        if (type === 'start') {
            setStartTime((prevTime) => ({
                ...prevTime,
                [timePart]: value,
            }));
        } else {
            setEndTime((prevTime) => ({
                ...prevTime,
                [timePart]: value,
            }));
        }
    };

    const formatDate = (date) => {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    };

    const combineDateTime = (date, time) => {
        return `${formatDate(date)} ${time.hour}:${time.minute}:${time.second}`;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const combinedStartDate = combineDateTime(formData.start_date, startTime);
        const combinedEndDate = combineDateTime(formData.end_date, endTime);

        if (!validateForm({ ...formData, start_date: combinedStartDate, end_date: combinedEndDate })) {
            setError("All the fields are mandatory");
            return;
        }

        setFeedbackMessage("Waiting for response");

        try {
            const response = await addSubscriptionForCustomerInAdmin(cid, x_token, x_user, { ...formData, start_date: combinedStartDate, end_date: combinedEndDate });

            if (response && response.data === "SUCCESS") {
                setError("");
                setFeedbackMessage("Subscription added successfully");
                fetchData(cid);
                setTimeout(() => {
                    setFormData({
                        "plan_id": "",
                        "start_date": new Date(),
                        "end_date": new Date(),
                        "currency": "",
                        "price": "",
                        "discount_percent": "",
                        "one_time_concession": "",
                        "one_time_fee": "",
                        "sold_by": "",
                        "managed_by": "",
                        "notes": ""
                    });
                    setFeedbackMessage("");
                    handleModalClose();
                }, 1000);

                console.log("Response is: ", response);

            }
        } catch (err) {
            setFeedbackMessage("");
            if (!err?.response) {
                setError('No Server Response, check your console for more details');
            } else if (addSubscriptionsError[err.response?.status]) {
                setError(addSubscriptionsError[err.response.status]);
            } else {
                setError('Failed');
            }
        }

    };



    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Add Subscription
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Fill in the details below to add a new subscription.
                </p>
            </div>

            <div className="flex-grow overflow-y-auto">
                <form className="flex flex-col pr-[10px]">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Plan id*"
                        placeholder="Select a plan"
                        id="plan_id"
                        type="select"
                        options={[
                            { value: "", label: "Select a plan" }, // Initial placeholder option
                            ...planOptionsState // Actual plan options
                        ]}
                        value={formData.plan_id}
                        onChange={handleInputChange}
                    />

                    <div className="relative mb-3">
                        <InputField
                            variant="admin"
                            extra="mb-3"
                            label="Start Date*"
                            value={formatDate(formData.start_date)}
                            type="text"
                            onClick={() => setShowStartDateCalendar(true)}
                        />

                        <MiniCalendar
                            value={formData.start_date}
                            onChange={(date) => {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    start_date: date,
                                }));
                                setShowStartDateCalendar(false);
                            }}
                            show={showStartDateCalendar}
                            onClose={() => setShowStartDateCalendar(false)}
                        />
                        {RenderTimeDropdown(startTime, handleTimeChange, 'start', 'Start Time*')}
                    </div>



                    <div className="relative mb-3">
                        <InputField
                            variant="admin"
                            extra="mb-3"
                            label="End Date*"
                            value={formatDate(formData.end_date)}
                            type="text"
                            onClick={() => setShowEndDateCalendar(true)}
                        />

                        <MiniCalendar
                            value={formData.end_date}
                            onChange={(date) => {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    end_date: date,
                                }));
                                setShowEndDateCalendar(false);
                            }}
                            show={showEndDateCalendar}
                            onClose={() => setShowEndDateCalendar(false)}
                        />
                        {RenderTimeDropdown(endTime, handleTimeChange, 'end', 'End Time*')}
                    </div>


                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Currency*"
                        placeholder="Select a currency"
                        id="currency"
                        type="select"
                        options={currencyOptions}
                        value={formData.currency}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Price*"
                        placeholder="Enter the price"
                        id="price"
                        type="text"
                        value={formData.price}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Discount Percent*"
                        placeholder="Enter discount percent"
                        id="discount_percent"
                        type="text"
                        value={formData.discount_percent}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="One Time Concession*"
                        placeholder="Enter one-time concession"
                        id="one_time_concession"
                        type="text"
                        value={formData.one_time_concession}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="One Time Fee*"
                        placeholder="Enter one-time fee"
                        id="one_time_fee"
                        type="text"
                        value={formData.one_time_fee}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Sold By*"
                        placeholder="Enter seller"
                        id="sold_by"
                        type="select"
                        options={[
                            { value: "", label: "Select a seller" }, // Initial placeholder option
                            ...managerSellerOptions // Actual seller options
                        ]}
                        value={formData.sold_by}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Managed By*"
                        placeholder="Enter manager"
                        id="managed_by"
                        type="select"
                        options={[
                            { value: "", label: "Select a manager" }, // Initial placeholder option
                            ...managerSellerOptions // Actual seller options
                        ]}
                        value={formData.managed_by}
                        onChange={handleInputChange}
                    />

                    <TextField
                        variant="admin"
                        extra="mb-3"
                        label="Notes*"
                        placeholder="Enter notes"
                        id="notes"
                        type="text"
                        value={formData.notes}
                        onChange={handleInputChange}
                    />
                </form>
            </div>

            <div className="sticky flex gap-4 ml-auto bottom-0 pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Add
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    );
};

export default AddSubscription;
