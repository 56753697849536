import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import updateSubscriptionInAdmin from '../../../../../axios/admin/subscriptions/updateSubscriptionInAdmin'
import { currencyOptions } from 'views/admin/customerDetails/variables/currencyOptions'
import TextField from 'components/fields/TextField'
import fetchDetailsFromSession from 'utils/fetchDetailsFromSession'
import validateForm from 'utils/validFormData'
import { updateSubscriptionError } from 'errors/error'

const UpdateSubscription = ({ handleModalClose, fetchData, subscription }) => {
    console.log("Subscription data: ", subscription)
    const [error, setError] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [formData, setFormData] = useState({
        "plan_id": subscription.plan_id !== undefined ? subscription.plan_id : "",
        "start_date": subscription.start_date !== undefined ? subscription.start_date : "",
        "end_date": subscription.end_date !== undefined ? subscription.end_date : "",
        "currency": subscription.currency !== undefined ? subscription.currency : "",
        "price": subscription.price !== undefined ? subscription.price : "",
        "discount_percent": subscription.discount_percent !== undefined ? subscription.discount_percent : "",
        "one_time_consession": subscription.one_time_consession !== undefined ? subscription.one_time_consession : "",
        "one_time_fee": subscription.one_time_fee !== undefined ? subscription.one_time_fee : "",
        "sold_by": subscription.sold_by !== undefined ? subscription.sold_by : "",
        "managed_by": subscription.managed_by !== undefined ? subscription.managed_by : "",
        "notes": subscription.notes !== undefined ? subscription.notes : ""
    });

    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)
    const [cid, setCid] = useState(null)
    const [sid, setSid] = useState(null)

    useEffect(() => {
        const res = fetchDetailsFromSession();
        setXToken(res.x_token)
        setXUser(res.x_user)
        setCid(subscription.customer_id)
        setSid(subscription.id)
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm(formData)) {
            setError("All the fields are mandatory")
            return
        }
        setFeedbackMessage("Waiting for response")

        try {
            const response = await updateSubscriptionInAdmin(cid, sid, x_token, x_user, formData.end_date)
            console.log(response)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMessage("End date updated successfully")
                fetchData()
                setTimeout(() => {
                    setFeedbackMessage("")
                    handleModalClose();
                }, 1000)

            }
        } catch (err) {
            setFeedbackMessage("")
            if (!err?.response) {
                setError("No Server Response, check your console for more details")
            } else if (updateSubscriptionError[err.response?.status]) {
                setError(updateSubscriptionError[err.response.status]);
            } else {
                setError('Failed')
            }
        }
    };

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Update subscription
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Update end date for current subscription
                </p>
            </div>

            <div className="flex-grow overflow-y-auto">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Plan id*"
                        placeholder="Enter plan id"
                        id="plan_id"
                        type="text"
                        value={formData.plan_id}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Start Date*"
                        placeholder="Enter start date"
                        id="start_date"
                        type="text"
                        value={formData.start_date}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="End Date*"
                        placeholder="Enter end date"
                        id="end_date"
                        type="text"
                        value={formData.end_date}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Currency*"
                        placeholder="Select currency"
                        id="currency"
                        type="select"
                        options={currencyOptions}
                        value={formData.currency}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Price*"
                        placeholder="Enter price"
                        id="price"
                        type="text"
                        value={formData.price}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Discount Percent*"
                        placeholder="Enter discount percent"
                        id="discount_percent"
                        type="text"
                        value={formData.discount_percent}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="One Time Concession*"
                        placeholder="Enter one-time concession"
                        id="one_time_concession"
                        type="text"
                        value={formData.one_time_consession}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="One Time Fee*"
                        placeholder="Enter one-time fee"
                        id="one_time_fee"
                        type="text"
                        value={formData.one_time_fee}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Sold By*"
                        placeholder="Enter sold by"
                        id="sold_by"
                        type="text"
                        value={formData.sold_by}
                        disabled={true}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Managed By*"
                        placeholder="Enter managed by"
                        id="managed_by"
                        type="text"
                        value={formData.managed_by}
                        disabled={true}
                    />

                    <TextField
                        variant="admin"
                        extra="mb-3"
                        label="Notes*"
                        placeholder="Enter notes"
                        id="notes"
                        type="text"
                        value={formData.notes}
                        disabled={true}
                    />
                </form>

            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0  pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Update
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    )
}

export default UpdateSubscription