const options = [
    {
        value: "0",
        label: "0"
    },
    {
        value: "1",
        label: "1"
    },
]

export default options