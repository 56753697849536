import React, { useState, useEffect } from "react";
import Card from "components/card";
import { IoMdCall } from "react-icons/io";
import { MdPermIdentity } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import Tab from "./Tabs/Tab";
import { useParams } from "react-router-dom";
import getCustomerInAdmin from "../../../../axios/admin/customer/getCustomerInAdmin";
import { FaUserEdit } from "react-icons/fa";
import UpdateCustomer from "views/admin/customer/components/UpdateCustomer";
import AddButton from "components/fixedPlugin/addButton";
import Modal from "components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setCustomer } from "../../../../redux/features/customerSlice";

const CustomerDetailsCard = () => {
  const [customerDetails, setCustomerDetails] = useState({});
  const { cid } = useParams();
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch()

  const user = useSelector((state) => state.customer.customer)
  console.log("user is: ", user)

  const fetchData = async (cid) => {
    try {
      const response = await getCustomerInAdmin(cid, sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'));
      if (response && response.statusText === 'OK') {
        setCustomerDetails(response.data[0]);
        dispatch(setCustomer(response.data[0]))
        setError('');
      } else {
        setError("An error occurred, try refreshing page");
        setFeedbackMessage('');
      }
    } catch (error) {
      setError(error.message);
      setFeedbackMessage('');
    }
  };

  useEffect(() => {
    fetchData(cid);
  }, [cid]);

  return (
    <Card extra={"relative w-full h-full p-3"}>
      {feedbackMessage !== "" && <p className="text-green-600">{feedbackMessage}</p>}
      {error !== "" && <p className="text-red-600">{error}</p>}
      <div className="grid grid-cols-4">
        <div className="border-r-2 border-dashed">
          {/* Header */}
          <div className="mt-2 mb-2 w-full">
            <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
              {customerDetails.contact_name}
            </h4>
          </div>
          {/* Cards */}
          <div className="grid gap-2 px-2 pb-4">
            <div className="mt-4 flex gap-4 font-medium">
              <IoMdCall className="mt-2 ml-2 rounded-full bg-brand-500 px-2 text-4xl font-bold text-white" />
              <div>
                <p className="">Phone: {customerDetails.contact_phone}</p>
                <p>Mobile: {customerDetails.mobile_otp}</p>
                <p>Landline: {customerDetails.landline}</p>
              </div>
            </div>

            <div className="mt-4 flex gap-4 font-medium">
              <MdPermIdentity className="mt-2 ml-2 rounded-full bg-brand-500 px-2 text-4xl font-bold text-white" />
              <div>
                <p className="">Customer ID: {customerDetails.cid}</p>
                <p>Login ID: {customerDetails.login_id}</p>
              </div>
            </div>

            <div className="mt-4 flex gap-4 font-medium">
              <IoLocationSharp className="mt-2 ml-2 rounded-full bg-brand-500 px-2 text-4xl font-bold text-white" />
              <div>
                <p>
                  {customerDetails.city}, {customerDetails.state}
                </p>

                <p>
                  {customerDetails.country}, {customerDetails.pin}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-3">
          <Tab customerDetails={customerDetails} />
        </div>
      </div>

      {/* Uppdate Button */}
      <div className="absolute bottom-4 right-4">
        <AddButton buttonIcon={<FaUserEdit className="w-[30px] h-[30px] m-1" />} modalComponent={<Modal component={<UpdateCustomer user={user} fetchData={()=>fetchData(cid)} />} />} />
      </div>

    </Card>
  );
};

export default CustomerDetailsCard;
