import { registerCustomer } from '../../../api/index';

const registerCustomerInAdmin = async (login_id, x_token, x_user, { entity, contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin }) => {
    try {
        return await registerCustomer(login_id, x_token, x_user, { entity, contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin })
    } catch (error) {
        console.log(error.message)
    }
}

export default registerCustomerInAdmin