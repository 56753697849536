import { getAllPlanFeatures } from "../../../api/plan_features"

const getAllPlanFeaturesInAdmin = async (x_token, x_user) => {
    try {
        return await getAllPlanFeatures(x_token, x_user)
    } catch (error) {
        console.log(error.message)
    }
}

export default getAllPlanFeaturesInAdmin