import { updateChain } from "../../../api/index"

const updateChainInAdmin = async (x_token, x_user, chain_id, { name, rpc_support, network, version }) => {
  try {
    return await updateChain(x_token, x_user, chain_id, { name, rpc_support, network, version })
  } catch (error) {
    console.log(error.message)
  }
}

export default updateChainInAdmin