export const columnsDataComplex = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "PASSWORD",
    accessor: "password",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
  {
    Header: "CREATED AT",
    accessor: "createdAt",
  }
];