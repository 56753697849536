import { getAllLoginsData } from '../../../api/index';

const getAllLogins = async (x_token, x_user) => {
    try {
        return await getAllLoginsData(x_token, x_user)
    } catch (error) {
       throw error
    }
}

export default getAllLogins