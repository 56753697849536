import passwordValidator from 'password-validator';

const schema = new passwordValidator();
schema
.is().min(8)      
.is().max(100)         
.has().uppercase()      
.has().lowercase()         
.has().digits(1)               
.has().not().spaces() 
.has().symbols(1)
.is().not().oneOf(['Passw0rd', 'Password123']);


export const validPassword = (password) => {
    console.log("validating password")
    return schema.validate(password)
}