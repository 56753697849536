import React, { useState, useEffect } from 'react'
import Card from 'components/card'
import approveCustomerInAdmin from '../../../../axios/admin/customer/approveCustomerInAdmin'

const ApproveCustomerComponent = ({ user, handleModalClose, fetchData }) => {
    const [feedbackMessage, setFeedbackMesage] = useState("")
    const [error, setError] = useState("")
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)

    useEffect(() => {
        const fetchDataFromSession = () => {
            setXToken(sessionStorage.getItem('x-token'))
            setXUser(sessionStorage.getItem('x-user'))
        }
        fetchDataFromSession()
    }, [])

    const handleButtonClick = async () => {
        setFeedbackMesage("Waiting for response")

        try {
            const response = await approveCustomerInAdmin(user.id, x_token, x_user)
            console.log("Response for suspend customer: ", response)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMesage("Customer approved successfully.")
                fetchData()
                setTimeout(() => {
                    handleModalClose()
                }, 1000)
            } else {
                setFeedbackMesage("")
                setError("An error occured, please try again.")
            }
        } catch (error) {
            setFeedbackMesage("")
            setError(error.message)
        }
    }

    return (
        <Card extra={"items-center w-full h-full p-[16px] text-navy-700 bg-cover shadow-none max-w-[400px] fixed inset-0 flex items-center justify-center z-50"}>
            <div className='flex flex-col gap-y-[20px]'>
                <div>
                    <p>Are you sure you want to Approve customer with email <span className='text-brand-600'>{user.email}</span></p>
                </div>
                {feedbackMessage && (
                    <div
                        className="text-center text-sm text-green-500"
                        style={{ transition: "opacity 0.35s ease-in" }}
                    >
                        {feedbackMessage}
                    </div>

                )}
                {error && (
                    <div
                        className="text-center text-sm text-red-500"
                        style={{ transition: "opacity 0.35s ease-in" }}
                    >
                        {error}
                    </div>

                )}
                <button
                    className={`linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-700 hover:bg-green-600 active:bg-green-700 dark:bg-green-500 dark:text-white dark:hover:bg-green-600 dark:active:bg-green-700`}
                    type="button"
                    onClick={handleButtonClick}
                >
                    Approve
                </button>

            </div>


        </Card>
    )
}

export default ApproveCustomerComponent