import updatePaymentsInAdmin from '../../../../axios/admin/payments/updatePaymentsInAdmin'
import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextField from 'components/fields/TextField'


const UpdatePayment = ({ payment, handleModalClose, fetchData }) => {
    const [error, setError] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [formData, setFormData] = useState({
        "date_of_instrument": payment.date_of_instrument && payment.date_of_instrument !== undefined ? payment.date_of_instrument : "",
        "date_of_payment": payment.date_of_payment && payment.date_of_payment !== undefined ? payment.date_of_payment : "",
        "amount": payment.amount && payment.amount !== undefined ? payment.amount : "",
        "receiving_account": payment.receiving_account && payment.receiving_account !== undefined ? payment.receiving_account : "",
        "receiving_bank": payment.receiving_bank && payment.receiving_bank !== undefined ? payment.receiving_bank : "",
        "reference_id": payment.reference_id && payment.reference_id !== undefined ? payment.reference_id : "",
        "subscription_id": payment.subscription_id && payment.subscription_id !== undefined ? payment.subscription_id : "",
        "invoice_id": payment.invoice_id && payment.invoice_id !== undefined ? payment.invoice_id : "",
        "notes": payment.notes && payment.notes !== undefined ? payment.notes : "",
    })
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)
    const admin_user = useSelector((state) => state.auth.user)

    useEffect(() => {
        const fetchDetailsFromSession = () => {
            setXToken(sessionStorage.getItem('x-token'))
            setXUser(sessionStorage.getItem('x-user'))
        }

        fetchDetailsFromSession();
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const validateForm = (formData) => {
        for (let key in formData) {
            if (formData[key] === "") {
                return false;
            }
        }
        return true;
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submit button clicked")
        console.log("Form data:", formData);
        if (!validateForm(formData)) {
            setError("All the fields are mandatory")
            return
        }
        setFeedbackMessage("Waiting for response")
        try {
            const response = await updatePaymentsInAdmin(payment.id, x_token, x_user, formData, admin_user)
            console.log(response)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMessage("Payment updated successfully")
                fetchData()
                setTimeout(() => {
                    setFeedbackMessage("")
                    handleModalClose();
                }, 1000)

            } else if (response && response.status === '404') {
                setFeedbackMessage("")
                setError("Validation error")
            } else {
                setError("Some error occured, please try again")
            }
        } catch (error) {
            setError(error.message)
        }

    };

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Update Payment
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Modify the details below to update payment
                </p>
            </div>

            <div className="flex-grow overflow-y-auto">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Date of Instrument*"
                        placeholder="Enter date of instrument"
                        id="date_of_instrument"
                        type="text"
                        value={formData.date_of_instrument}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Date of Payment*"
                        placeholder="Enter date of payment"
                        id="date_of_payment"
                        type="text"
                        value={formData.date_of_payment}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Amount*"
                        placeholder="Enter amount"
                        id="amount"
                        type="text"
                        value={formData.amount}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Receiving Account*"
                        placeholder="Enter receiving account"
                        id="receiving_account"
                        type="text"
                        value={formData.receiving_account}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Receiving Bank*"
                        placeholder="Enter receiving bank"
                        id="receiving_bank"
                        type="text"
                        value={formData.receiving_bank}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Reference Id*"
                        placeholder="Enter reference id"
                        id="reference_id"
                        type="text"
                        value={formData.reference_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Subscription Id*"
                        placeholder="Enter subscription id"
                        id="subscription_id"
                        type="text"
                        value={formData.subscription_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Invoice Id*"
                        placeholder="Enter invoice id"
                        id="invoice_id"
                        type="text"
                        value={formData.invoice_id}
                        onChange={handleInputChange}
                    />

                    <TextField
                        variant="admin"
                        extra="mb-3"
                        label="Notes*"
                        placeholder="Enter notes"
                        id="notes"
                        type="text"
                        value={formData.notes}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Admin User*"
                        placeholder="Enter admin user"
                        id="admin_user"
                        value={admin_user}
                        type="text"
                        disabled={true}
                    />
                </form>

            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0 pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Update
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    )
}

export default UpdatePayment