import { updateCustomer } from '../../../api/index';

const updateCustomerInAdmin = async (cid, x_token, x_user, { contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin, bank_name, bank_account_holder, bank_ifsc, bank_account }) => {
    try {
        return await updateCustomer(cid, x_token, x_user, { contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin, bank_name, bank_account_holder, bank_ifsc, bank_account })
    } catch (error) {
        console.log(error.message)
    }
}

export default updateCustomerInAdmin