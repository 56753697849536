import { approveCustomer } from "../../../api/index";

const approveCustomerInAdmin = async (login_id, x_token, x_user) => {
    try {
        return await approveCustomer (login_id, x_token, x_user)
    } catch (error) {
        console.log(error.message)
    }
}

export default approveCustomerInAdmin;