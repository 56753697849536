import { deletePayment } from "../../../api/payments"

const deletePayemntInAdmin = async (pid, x_token, x_user) => {
  try {
    return await deletePayment(pid, x_token, x_user)
  } catch (error) {
    console.log(error.message)
  }
}

export default deletePayemntInAdmin