/* Customer */
export const createLoginErrors = {
    405: "User is already created/registered with this email.",
    406: "Login is created but customer is not registered with this login.",
    415: "Email ID format is incorrect",
    420: "Password Validation Failed"
} // Done

export const registerCustomerErrors = {
    404: "City is empty or Address_1 is empty",
    405: "Customer is already registered with this LID",
    418: "Mobile number is already in use for another login",
    500: "Exception"
} // Done


/* Chains */

export const deleteChainErrors = {
    401: "The chain is referenced in some subscription, cannot be deleted",
    500: "Exception occurred in DB or system"
} // Done

export const getChainErrors = {
    501: "Chain_id does not exist"
} 

export const addChainError = {
    404: "Validation failed, some field missing or is of <3 chars in length.",
    410: "Integrity failed. The combination of Name+Network+Version is not unique. Same combination already exists",
    500: "Exception occurred in the system"
} // Done

export const getAllChains = {
    201: "No Chains found in DB",
    500: "Exception in system"
} // Done

/* Plans */

export const getAllPlanErrors = {
    201: "No plans found in DB",
    500: "Exception in system"
} // Done

export const deletePlanError = {
    401: "PF_ID provided is invalid, no entry probably exists for it",
    402: " The plan feature has acorresponding plan_id forwhich there is an activesubscription, we cannot therefore delete it yet.",
    500: "Exception in system"
} // Done


/* Plan Features */

export const getPlanFeatureByPlanIdError = {
    201: "No plan exists for the provided plan_id",
    500: "Exception"
}

export const getPlanFeatureByIdError = {
    201: "Provided plan feature id does not exist"
}

export const addPlanFeatureError = {
    404: "Validation failed",
    431: "Plan feature for this plan ID and chain ID already exists",
    500: "Other Exception"
} // Done

/* Subscriptions */

export const addSubscriptionsError = {
    402: "CID / Customer provided does not exist",
    405: "Customer is not in APPROVED state yet",
    431: "Chosen plan for subscription is not active",
    433: "Customer is already subscribed to the plan",
    439: "Currency is Not INR for an Indian Customer. Currency should be INR for Indian Customer.",
    500: "Exception"
} // Done

export const deleteSubscriptionError = {
    405: "The customer subscription has invoices created. Please delete the invoices first to delete subscription",
    406: "The subscription has payments. Please delete the payments first before deleting subscription",
    500: "Exception"
} // Done

export const updateSubscriptionError = {
    401: "New EndDateTime is not incorrect format of YYYY-MM-DD HH:MM:SS",
    500: "Exception"
} // Done

/* Payment */

export const addPaymentError = {
    433: "Invoice_id is not Numeric 434 Receiving account number length is less than 5 chars",
    435: "Receiving Bank/PG is less than 4 chars",
    436: "Amount is not a float quantity",
    439: "Payment appears to be duplicate entry. A similar entry exists, please check.",
    407: "Customer does not own the subscription id specified in subscription_id field"
} // Done

export const deletePaymentError = {
    500: "Exception or PID not found."
} 

export const updatePaymentError = {
    433: "Invoice_id is not Numeric 434 Receiving account number length is less than 5 chars",
    435: "Receiving Bank/PG is less than 4 chars",
    436: "Amount is not a float quantity",
    439: "Payment appears to be duplicate entry. A similar entry exists, please check.",
    407: "Customer does not own the subscription id specified in subscription_id field"
} 