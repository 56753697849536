import React from "react";
import Dropdown from "components/dropdown";

function StatusMenu(props) {
  const { transparent, status } = props;

  return (
    <>
      <Dropdown
        button={
          <button
            className={`flex items-center text-sm cursor-default ${transparent
              ? "bg-none text-black hover:bg-none active:bg-none"
              : "hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            {status}
          </button>
        }
        animation={"origin-top-right transition-all duration-300 ease-in-out"}
        classNames={`${transparent ? "top-4" : "top-5"} right-0 w-max`}
      />
    </>
  );
}

export default StatusMenu;
