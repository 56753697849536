import React, { useState } from 'react';

function FixedPlugin({ buttonIcon, modalComponent }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopupState = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      <button
        className="border-px fixed bottom-[30px] right-[35px] !z-[99] flex h-[60px] w-[60px] items-center justify-center rounded-full border-[#6a53ff] bg-gradient-to-br from-brandLinear to-blueSecondary p-0"
        onClick={handleShowPopupState}
      >
        <div className="cursor-pointer text-gray-600">
          {buttonIcon}
        </div>
      </button>
      {showPopup && (
        <div>
          <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-60 backdrop-filter backdrop-blur-sm z-40">
            {React.cloneElement(modalComponent, { handleModalClose: handleShowPopupState })}
          </div>
        </div>
      )}
    </div>
  )
}

export default FixedPlugin;
