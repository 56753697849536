import { addSubscriptionForCustomer } from "../../../api/subscription"

const addSubscriptionForCustomerInAdmin = async (cid, x_token, x_user, { plan_id, start_date, end_date, currency, price, discount_percent, one_time_concession, one_time_fee, sold_by, managed_by, notes }) => {
  try {
    return await addSubscriptionForCustomer(cid, x_token, x_user, { plan_id, start_date, end_date, currency, price, discount_percent, one_time_concession, one_time_fee, sold_by, managed_by, notes })
  } catch (error) {
    throw error
  }
}

export default addSubscriptionForCustomerInAdmin  

// {
//   "plan_id": "5",
//   "start_date": "2024-05-12 10:40:56",
//   "end_date": "2024-05-13 10:40:56",
//   "currency": "INR",
//   "price": "500",
//   "discount_percent": "8",
//   "one_time_concession": "50",
//   "one_time_fee": "100",
//   "sold_by": "Vivek",
//   "managed_by": "Deepak",
//   "notes": "New notes"
// }