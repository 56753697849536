import React, { useRef, useState } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import countries from "../variables/countries";
import states from "../variables/states";
import { useEffect } from "react";
import registerCustomerInAdmin from "../../../../axios/admin/customer/registerCustomerInAdmin";
import fetchDetailsFromSession from "utils/fetchDetailsFromSession";
import validateForm from "utils/validFormData";
import { registerCustomerErrors } from "errors/error";

export default function RegisterLogins({ user, handleModalClose, fetchData }) {
  const entityRef = useRef();
  const [formData, setFormData] = useState({
    entity: "",
    contact_name: "",
    contact_phone: "",
    mobile_otp: "",
    gstin: "",
    pan: "",
    address_1: "",
    address_2: "",
    landline: "",
    city: "",
    pin: "",
    state: "",
    country: "",
    msme: "",
    cin: ""
  });

  const [x_token, setXToken] = useState(null)
  const [x_user, setXUser] = useState(null)
  const [loginId, setLoginId] = useState(null)
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [error, setError] = useState("");

  // Setting focus on entity field on component load
  useEffect(() => {
    entityRef.current.focus();
  }, [])

  // fecthing details from session
  useEffect(() => {
    const res = fetchDetailsFromSession()
    setXToken(res.x_token)
    setXUser(res.x_user)
    setLoginId(user.id)
  }, [])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm(formData)) {
      setFeedbackMessage("");;
      setError("All fields are mandatory");
      return;
    }
    setFeedbackMessage("Waiting for response")

    try {
      const response = await registerCustomerInAdmin(loginId, x_token, x_user, formData)
      if (response && response.status === '200') {
        setFeedbackMessage("Customer registered successfully")
        setError("")
        fetchData()
        setTimeout(() => {
          setFeedbackMessage("")
          setFormData({
            entity: "",
            contact_name: "",
            contact_phone: "",
            mobile_otp: "",
            gstin: "",
            pan: "",
            address_1: "",
            address_2: "",
            landline: "",
            city: "",
            pin: "",
            state: "",
            country: "",
            msme: "",
            cin: ""
          })
          handleModalClose()
        }, 1000)
      }
    } catch (err) {
      setFeedbackMessage("")
      if (!err?.response) {
        setError('No Server Response, check your console for more details');
      } else if (registerCustomerErrors[err.response?.status]) {
        setError(registerCustomerErrors[err.response.status]);
      } else {
        setError('Failed')
      }
    }
  };

  return (
    <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
      <div className="mb-4 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Register Login
        </h4>
        <p className="mt-2 mb-4 text-base text-gray-600">
          Login created for this customer. Fill details to register the
          customer.
        </p>
        <div className="flex align-middle justify-between gap-3 text-navy-700 text-sm border-b pb-4">
          <p className="font-semibold">{user.id}</p>
          <p className="font-semibold">{user.email}</p>
          <p className="bg-green-100 text-green-700 rounded-md px-4 py-1 font-semibold">{user.status}</p>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto">
        <form onSubmit={handleFormSubmit} className="flex flex-col">
          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Entity*"
              placeholder="Customer"
              id="entity"
              type="text"
              value={formData.entity}
              ref={entityRef}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="Contact Name*"
              placeholder="John Doe"
              id="contact_name"
              type="text"
              value={formData.contact_name}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Contact Phone"
              placeholder="+1 9876543210"
              id="contact_phone"
              type="text"
              value={formData.contact_phone}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="Mobile OTP*"
              placeholder="123456"
              id="mobile_otp"
              type="text"
              value={formData.mobile_otp}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="GST In"
              placeholder="07AAFPM8384G1ZD"
              id="gstin"
              type="text"
              value={formData.gstin}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="PAN Card No"
              placeholder="AAFPM8384G"
              id="pan"
              type="text"
              value={formData.pan}
              onChange={handleInputChange}
            />
          </div>

          <TextField
            label="Permanent Address"
            id="address_1"
            placeholder="e.g Salimar Bagh, MG Road Near DLF Mall"
            extra='mb-3'
            value={formData.address_1}
            onChange={handleInputChange}
          />

          <TextField
            label="Correspondence Address"
            id="address_2"
            placeholder="e.g Salimar Bagh, MG Road Near DLF Mall"
            extra='mb-3'
            value={formData.address_2}
            onChange={handleInputChange}
          />

          <div className="grid md:grid-cols-3 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Landline"
              placeholder="+1 9876543210"
              id="landline"
              type="text"
              value={formData.landline}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="City"
              placeholder="New Delhi"
              id="city"
              type="text"
              value={formData.city}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="Pin"
              placeholder="123456"
              id="pin"
              type="text"
              value={formData.pin}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Country"
              placeholder="select a country"
              id="country"
              type="select"
              options={countries}
              value={formData.country}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="State"
              placeholder="select a state"
              id="state"
              type="select"
              options={states}
              value={formData.state}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="MSME"
              placeholder="MSME"
              id="msme"
              type="text"
              value={formData.msme}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="CIN"
              placeholder="f3431311"
              id="cin"
              type="text"
              value={formData.cin}
              onChange={handleInputChange}
            />
          </div>
        </form>
      </div>
      <div className="sticky flex mx-auto bottom-0 pt-4">
        <button
          className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          type="submit"
          onClick={handleFormSubmit}
        >
          Register
        </button>

      </div>
      {feedbackMessage && (
        <div
          className="mt-4 text-center text-sm text-green-500"
          style={{ transition: "opacity 0.35s ease-in" }}
        >
          {feedbackMessage}
        </div>
      )}
      {error && (
        <div
          className="mt-4 text-center text-sm text-red-400"
          style={{ transition: "opacity 0.35s ease-in" }}
        >
          {error}
        </div>
      )}
    </Card>
  );
}
