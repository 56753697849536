import { addChain } from "../../../api/index"

const addChainInAdmin = async (x_token, x_user, { name, rpc_support, network, version }) => {
  try {
    return await addChain(x_token, x_user, { name, rpc_support, network, version })
  } catch (error) {
    throw error;
  }
}

export default addChainInAdmin