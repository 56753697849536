import { getCustomer } from '../../../api/index';

const getCustomerInAdmin = async (cid, x_token, x_user) => {
    try {
        return await getCustomer(cid, x_token, x_user)
    } catch (error) {
        console.log(error.message)
    }
}

export default getCustomerInAdmin