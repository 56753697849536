import axios from "axios";
import { BASE_URL } from "./index";

export const getAllPlanFeatures = (x_token, x_user) => axios.post(`${BASE_URL}/get_all_plan_features`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const addPlanFeatures = (x_token, x_user, { name, plan_id, chain_id, tps, note }) => axios.post(`${BASE_URL}/add_plan_feature`, 
    {
        name, plan_id, chain_id, tps, note
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const deletePlanFeature = (x_token, x_user, pf_id) => axios.post(`${BASE_URL}/delete_plan_fetaure?pf_id=${pf_id}`, 
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)
