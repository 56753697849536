import { deleteChain } from "../../../api/index"

const deleteChainInAdmin = async (x_token, x_user, chain_id) => {
  try {
    return await deleteChain(x_token, x_user, chain_id)
  } catch (error) {
    throw error
  }
}

export default deleteChainInAdmin