import axios from 'axios'

const BASE_URL = 'https://a.d2c.in:8000/admin'

/*auth*/

export const verifyUser = (email, password) => axios.post(`${BASE_URL}/admin_login`, {
    email: email,
    password: password
})

export const validateOtp = (otp, x_user, x_token) => axios.post(`${BASE_URL}/admin_verify_otp?otp=${otp}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

/* customer */

export const getAllLoginsData = (x_token, x_user) => axios.post(`${BASE_URL}/admin_get_all_logins`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const createLogin = (email, password, x_token, x_user) => axios.post(`${BASE_URL}/create_login`,
    {
        email,
        password
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const registerCustomer = (login_id, x_token, x_user, { entity, contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin }) => axios.post(`${BASE_URL}/register_customer?login_id=${login_id}`,
    {
        entity, contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const suspendCustomer = (login_id, x_token, x_user) => axios.post(`${BASE_URL}/suspend_customer?login_id=${login_id}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)
export const approveCustomer = (login_id, x_token, x_user) => axios.post(`${BASE_URL}/approve_customer?login_id=${login_id}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const updateCustomer = (cid, x_token, x_user, { contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin, bank_name, bank_account_holder, bank_ifsc, bank_account }) => axios.post(`${BASE_URL}/update_customer?cid=${cid}`,
    {
        contact_name, contact_phone, mobile_otp, gstin, pan, address_1, address_2, landline, city, pin, state, country, msme, cin, bank_name, bank_account_holder, bank_ifsc, bank_account
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const getAdminLogins = (x_token, x_user) => axios.post(`${BASE_URL}/admin_get_all_admin_logins`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

/* chain */

export const addChain = (x_token, x_user, { name, rpc_support, network, version }) => axios.post(`${BASE_URL}/add_chain`,
    {
        name,
        rpc_support,
        network,
        version
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const getAllChain = (x_token, x_user) => axios.post(`${BASE_URL}/get_all_chains`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const deleteChain = (x_token, x_user, chain_id) => axios.post(`${BASE_URL}/delete_chain?chain_id=${chain_id}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const updateChain = (x_token, x_user, chain_id, { name, rpc_support, network, version }) => axios.post(`${BASE_URL}/update_chain?chain_id=${chain_id}`,
    {
        name,
        rpc_support,
        network,
        version
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const getCustomer = (cid, x_token, x_user) => axios.post(`${BASE_URL}/admin_get_cust?cid=${cid}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)


/*Plans*/

export const getAllPlans = (x_token, x_user) => axios.post(`${BASE_URL}/get_all_plans`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const addPlan = (x_token, x_user, { name, price, frequency, setup_price, trial_period_days, grace_days, status, notes }) => axios.post(`${BASE_URL}/add_plan`,
    {
        name, price, frequency, setup_price, trial_period_days, grace_days, status, notes
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const deletePlan = (x_token, x_user, plan_id) => axios.post(`${BASE_URL}/delete_plan?plan_id=${plan_id}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const getPlan = (x_token, x_user, plan_id) => axios.post(`${BASE_URL}/get_plan?plan_id=${plan_id}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const updatePlan = (x_token, x_user, plan_id, { name, price, frequency, setup_price, trial_period_days, grace_days, status, notes }) => axios.post(`${BASE_URL}/update_plan?plan_id=${plan_id}`,
    {
        name, price, frequency, setup_price, trial_period_days, grace_days, status, notes
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export { BASE_URL }