import axios from "axios";
import { BASE_URL } from "./index";

export const getCustomerWithlid = (lid, x_token, x_user) => axios.post(`${BASE_URL}/admin_get_cust_with_lid?lid=${lid}`, 
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)