import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useRef, useState } from 'react'
import addChainInAdmin from '../../../../axios/admin/chain/addChainInAdmin'
import fetchDetailsFromSession from 'utils/fetchDetailsFromSession'
import options from '../variables/options'
import validateForm from 'utils/validFormData'
import { addChainError } from 'errors/error'


const AddChain = ({ handleModalClose, fetchData }) => {
    const nameRef = useRef();
    const [error, setError] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        rpc_support: '0',
        network: '',
        version: ''
    })
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)

    // Fetching details from session
    useEffect(() => {
        const res = fetchDetailsFromSession();
        setXToken(res.x_token)
        setXUser(res.x_user)
    }, [])

    // setting focus to name field on component load
    useEffect(() => {
        nameRef.current.focus()
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Checking all fields are filled or not
        if (!validateForm(formData)) {
            setError("All the fields are mandatory")
            return
        }

        setFeedbackMessage("Waiting for response")

        try {
            const response = await addChainInAdmin(x_token, x_user, formData)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMessage("Chain added successfully")
                fetchData()
                setTimeout(() => {
                    setFormData({
                        name: '',
                        rpc_support: '',
                        network: '',
                        version: ''
                    })
                    setFeedbackMessage("")
                    handleModalClose();
                }, 1000)
            }
        } catch (err) {
            setFeedbackMessage("")
            if (!err?.response) {
                setError("No Server Response, check your console for more details")
            } else if (addChainError[err.response?.status]) {
                setError(addChainError[err.response.status]);
            } else {
                setError('Failed')
            }
        }
    };

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Add Chain
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Fill in the details below to add a new chain.
                </p>
            </div>

            <div className="flex-grow overflow-y-auto">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Name*"
                        placeholder="name"
                        id="name"
                        type="text"
                        ref={nameRef}
                        value={formData.name}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="rpc_support*"
                        placeholder="12345"
                        id="rpc_support"
                        type="select"
                        options={options}
                        value={formData.rpc_support}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Network*"
                        placeholder="network"
                        id="network"
                        type="text"
                        value={formData.network}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Version*"
                        placeholder="123456"
                        id="version"
                        type="text"
                        value={formData.version}
                        onChange={handleInputChange}
                    />

                </form>
            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0 pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Add
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    )
}

export default AddChain