import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PaymentsTable from "./components/PaymentsTable";
import { paymentsColumn, subscriptionColumn } from "./variables/columnData";
import getPaymentsForCustomerInAdmin from "../../../axios/admin/payments/getPaymentsForCustomerInAdmin";
import getSubscriptionForCustomerInAdmin from "../../../axios/admin/subscriptions/getSubscriptionForCustomerInAdmin";
import SubscriptionTable from "./components/SubscriptionTable";
import CustomerDetailsCard from "./components/CustomerDetailsCard";

const GetCustomer = () => {
  const { cid } = useParams();
  const [paymentsData, setPaymentsData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [error, setError] = useState();

  const fetchPaymentData = async (cid) => {
    console.log("in fetchData");
    const response = await getPaymentsForCustomerInAdmin(
      cid,
      sessionStorage.getItem("x-token"),
      sessionStorage.getItem("x-user")
    );
    try {
      console.log("Response of getCustomer: ", response);
      if (response && response.statusText === "OK") {
        console.log("In response");
        setError("");
        setPaymentsData(response.data);
      } else {
        setError(response.status);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchSubscriptionData = async (cid) => {
    console.log("in fetch subscription Data");
    const response = await getSubscriptionForCustomerInAdmin(
      cid,
      sessionStorage.getItem("x-token"),
      sessionStorage.getItem("x-user")
    );
    try {
      console.log("Response of getCustomer: ", response);
      if (response && response.statusText === "OK") {
        console.log("In response");
        setError("");
        setSubscriptionData(response.data);
      } else {
        setError(response.status);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    console.log("In use effect");
    fetchSubscriptionData(cid);
    fetchPaymentData(cid);
  }, [cid]);

  return (
    <>
      <div className="flex flex-col gap-y-[100px]">
        <CustomerDetailsCard />
        <SubscriptionTable
          columnsData={subscriptionColumn}
          tableData={subscriptionData}
          error={error}
          fetchData={fetchSubscriptionData}
        />
        <PaymentsTable
          columnsData={paymentsColumn}
          tableData={paymentsData}
          error={error}
          fetchData={fetchPaymentData}
        />
      </div>
    </>
  );
};

export default GetCustomer;
