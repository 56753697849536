import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useEffect, useRef, useState } from "react";
import OtpBox from "./OtpBox";
import { verifyCredentials } from "../../axios/auth/verifyCredentials";

function SignIn() {
  const userRef = useRef();

  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })
  const [validFormData, setValidFormData] = useState(false)

  const [step, setStep] = useState(1);
  const [error, setError] = useState('');

  const [loginRes, setLoginRes] = useState({
    x_user: '',
    x_token: ''
  });

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (formData.email === "" || formData.password === "") {
      setValidFormData(false)
      return
    }

    setValidFormData(true)
  }, [formData])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  }

  const handleFormSubmit = async (e) => {
    console.log("Handle form submit")
    e.preventDefault()

    if (!formData.email || !formData.password) {
      setError("Email or Passwors can't be empty")
      return
    }

    try {
      const response = await verifyCredentials(formData.email, formData.password)
      if (response.data.status === '200') {
        setError('')
        setLoginRes({
          x_user: response.data.x_user,
          x_token: response.data.x_token
        })
        setStep(2)
      } else if (response.data.status === '410' || response.data.status === '401') {
        setError("Email or password is wrong")
      } else if (response.data.status === '422') {
        setError("Validation Error")
      } else {
        setError("Sign-in again")
      }
    } catch (err) {
      if (!err?.response) {
        setError('No Server Response');
      } else if (err.response?.status === 410 || err.response?.status === 401) {
        setError('Username Taken');
      } else {
        setError('Registration Failed')
      }
    }
  }

  return (
    <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10  lg:justify-start">
      {
        step === 1 && (
          <>
            {/* Sign in section */}
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] ">
              <h4 className="mb-2.5 text-4xl font-bold text-navy-700  dark:text-white">
                Sign In
              </h4>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Enter your email and password to sign in!
              </p>
              <form onSubmit={handleFormSubmit}>
                {/* Email */}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Email*"
                  placeholder="mail@simmmple.com"
                  id="email"
                  type="text"
                  name="email"
                  value={formData.email}
                  ref={userRef}
                  onChange={handleInputChange}
                />

                {/* Password */}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Password*"
                  placeholder="Min. 8 characters"
                  id="password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  showIcon={true}
                />
                {
                  error !== '' && (
                    <div className="text-red-600 text-center my-[10px]">
                      <span>{error}</span>
                    </div>
                  )
                }

                {/* Checkbox */}
                <div className="mb-4 flex items-center justify-between px-2">
                  <div className="flex items-center">
                    <Checkbox />
                    <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                      Keep me logged In
                    </p>
                  </div>
                  <a
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    href=" "
                  >
                    Forgot Password?
                  </a>
                </div>
                <button
                  disabled={!validFormData}
                  className={`linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`} type="submit"
                >
                  Sign in
                </button>
              </form>

              <div className="mt-4">
                <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                  Not registered yet?
                </span>
                <a
                  href=" "
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                  Create an account
                </a>
              </div>
            </div>
          </>
        )
      }

      {
        step === 2 && (
          <>
            <OtpBox loginRes={loginRes} email={formData.email} />
          </>
        )
      }

    </div>

  );
}

export default SignIn;