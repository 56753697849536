import { addPayments } from "../../../api/payments"

const addPaymentsInAdmin = async (cid, x_token, x_user, { date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes }, admin_user) => {
  try {
    return await addPayments(cid, x_token, x_user, { date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes }, admin_user)
  } catch (error) {
    throw error;
  }
}

export default addPaymentsInAdmin