import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customer: {}
}

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload
            console.log("payload is: ", action.payload)
        },
        updateCustomer: (state, action) => {
            state.customer = action.payload
        }
    }
})

export const { setCustomer, updateCustomer } = customerSlice.actions;
export default customerSlice.reducer;