import axios from "axios";
import { BASE_URL } from "./index";

export const getPaymetForCustomer = (cid, x_token, x_user) => axios.post(`${BASE_URL}/get_all_payments_for_customer?cid=${cid}`, 
    {}, 
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const addPayments = (cid, x_token, x_user, { date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes }, admin_user) => axios.post(`${BASE_URL}/add_payment?cid=${cid}`, 
    {
        date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes, admin_user
    },
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const getAllPayments = (x_token, x_user) => axios.post(`${BASE_URL}/get_all_payments`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const updatePayments = (pid, x_token, x_user, { date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes }, admin_user) => axios.post(`${BASE_URL}/update_payment?pid=${pid}`, 
    {
        date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes, admin_user
    }, 
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)

export const deletePayment = (pid, x_token, x_user) => axios.post(`${BASE_URL}/delete_payment?pid=${pid}`,
    {},
    {
        headers: {
            'x-token': x_token,
            'x-user': x_user
        }
    }
)