import React, { useState } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import countries from "../variables/countries";
import states from "../variables/states";
import { useEffect } from "react";
import updateCustomerInAdmin from "../../../../axios/admin/customer/updateCustomerInAdmin";
import { useParams } from "react-router-dom";

export default function UpdateCustomer({ user, handleModalClose, fetchData }) {
  const [formData, setFormData] = useState({
    entity: user.entity && user.entity !== undefined ? user.entity : "",
    contact_name: user.contact_name && user.contact_name !== undefined ? user.contact_name : "",
    contact_phone: user.contact_phone && user.contact_phone !== undefined ? user.contact_phone : "",
    mobile_otp: user.mobile_otp && user.mobile_otp !== undefined ? user.mobile_otp : "",
    gstin: user.gstin && user.gstin !== undefined ? user.gstin : "",
    pan: user.pan && user.pan !== undefined ? user.pan : "",
    address_1: user.address_1 && user.address_1 !== undefined ? user.address_1 : "",
    address_2: user.address_2 && user.address_2 !== undefined ? user.address_2 : "",
    landline: user.landline && user.landline !== undefined ? user.landline : "",
    city: user.city && user.city !== undefined ? user.city : "",
    pin: user.pin && user.pin !== undefined ? user.pin : "",
    state: user.state && user.state !== undefined ? user.state : "",
    country: user.country && user.country !== undefined ? user.country : "",
    msme: user.msme && user.msme !== undefined ? user.msme : "",
    cin: user.cin && user.cin !== undefined ? user.cin : "",
    bank_name: user.bank_name && user.bank_name !== undefined ? user.bank_name : "",
    bank_account_holder: user.bank_account_holder && user.bank_account_holder !== undefined ? user.bank_account_holder : "",
    bank_ifsc: user.bank_ifsc && user.bank_ifsc !== undefined ? user.bank_ifsc : "",
    bank_account: user.bank_account && user.bank_account !== undefined ? user.bank_account : ""
  });

  const [x_token, setXToken] = useState(null)
  const [x_user, setXUser] = useState(null)
  const [cid, setCid] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDefaults = () => {
      setXToken(sessionStorage.getItem('x-token'))
      setXUser(sessionStorage.getItem('x-user'))
      setCid(user.cid)
    }
    fetchDefaults()
  }, [])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  }

  const validateForm = () => {
    console.log("form data in function: ", formData)
    for (let key in formData) {
      if (formData[key] === "") {
        return false;
      }
    }
    console.log("form data in function1: ", formData)
    return true;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    if (validateForm()) {
      setFeedbackMessage("Waiting for response")
      try {
        const response = await updateCustomerInAdmin(cid, x_token, x_user, formData)
        if (response && response.statusText === 'OK') {
          setFeedbackMessage("Customer registered successfully")
          setError("")
          fetchData(cid)
          setTimeout(() => {
            setFeedbackMessage("")
            handleModalClose()
          }, 1000)
        }
      } catch (error) {
        setFeedbackMessage("")
        setError(error.message)
      }
    } else {
      setFeedbackMessage("")
      setError("All fields are required")
    }
  };

  return (
    <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
      <div className="mb-4 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Update Customer
        </h4>
        <p className="mt-2 mb-4 text-base text-gray-600">
          Customer is registered. Fill in more details below to approve customer.
        </p>
        <div className="flex align-middle justify-between gap-3 text-navy-700 text-sm border-b pb-4">
          <p className="font-semibold">{user.id}</p>
          <p className="font-semibold">{user.email}</p>
          <p className="bg-green-100 text-green-700 rounded-md px-4 py-1 font-semibold">{user.status}</p>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto">
        <form onSubmit={handleFormSubmit} className="flex flex-col">
          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Entity*"
              placeholder="Customer"
              id="entity"
              type="text"
              value={formData.entity && formData.entity !== undefined ? formData.entity : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="Contact Name*"
              placeholder="John Doe"
              id="contact_name"
              type="text"
              value={formData.contact_name && formData.contact_name !== undefined ? formData.contact_name : ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Contact Phone"
              placeholder="+1 9876543210"
              id="contact_phone"
              type="text"
              value={formData.contact_phone && formData.contact_phone !== undefined ? formData.contact_phone : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="Mobile OTP*"
              placeholder="123456"
              id="mobile_otp"
              type="text"
              value={formData.mobile_otp && formData.mobile_otp !== undefined ? formData.mobile_otp : ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="GST In"
              placeholder="07AAFPM8384G1ZD"
              id="gstin"
              type="text"
              value={formData.gstin && formData.gstin !== undefined ? formData.gstin : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="PAN Card No"
              placeholder="AAFPM8384G"
              id="pan"
              type="text"
              value={formData.pan && formData.pan !== undefined ? formData.pan : ""}
              onChange={handleInputChange}
            />
          </div>

          <TextField
            label="Permanent Address"
            id="address_1"
            placeholder="e.g Salimar Bagh, MG Road Near DLF Mall"
            extra='mb-3'
            value={formData.address_1 && formData.address_1 !== undefined ? formData.address_1 : ""}
            onChange={handleInputChange}
          />

          <TextField
            label="Correspondence Address"
            id="address_2"
            placeholder="e.g Salimar Bagh, MG Road Near DLF Mall"
            extra='mb-3'
            value={formData.address_2 && formData.address_2 !== undefined ? formData.address_2 : ""}
            onChange={handleInputChange}
          />

          <div className="grid md:grid-cols-3 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Landline"
              placeholder="+1 9876543210"
              id="landline"
              type="text"
              value={formData.landline && formData.landline !== undefined ? formData.landline : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="City"
              placeholder="New Delhi"
              id="city"
              type="text"
              value={formData.city && formData.city !== undefined ? formData.city : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="pin"
              placeholder="123456"
              id="pin"
              type="text"
              value={formData.pin && formData.pin !== undefined ? formData.pin : ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Country"
              placeholder="select a country"
              id="country"
              type="select"
              options={countries}
              value={formData.country && formData.country !== undefined ? formData.country : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="State"
              placeholder="select a state"
              id="state"
              type="select"
              options={states}
              value={formData.state && formData.state !== undefined ? formData.state : ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="MSME"
              placeholder="MSME"
              id="msme"
              type="text"
              value={formData.msme && formData.msme !== undefined ? formData.msme : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="CIN"
              placeholder="f3431311"
              id="cin"
              type="text"
              value={formData.cin && formData.cin !== undefined ? formData.cin : ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="BANK NAME"
              placeholder="BANK NAME"
              id="bank_name"
              type="text"
              value={formData.bank_name && formData.bank_name !== undefined ? formData.bank_name : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="BANK ACCOUNT HOLDER"
              placeholder="BANK ACCOUNT HOLDER"
              id="bank_account_holder"
              type="text"
              value={formData.bank_account_holder && formData.bank_account_holder !== undefined ? formData.bank_account_holder : ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-2">
            <InputField
              variant="auth"
              extra="mb-3"
              label="BANK IFSC"
              placeholder="BANK IFSC"
              id="bank_ifsc"
              type="text"
              value={formData.bank_ifsc && formData.bank_ifsc !== undefined ? formData.bank_ifsc : ""}
              onChange={handleInputChange}
            />

            <InputField
              variant="auth"
              extra="mb-3"
              label="BANK ACCOUNT"
              placeholder="BANK ACCOUNT"
              id="bank_account"
              type="text"
              value={formData.bank_account && formData.bank_account !== undefined ? formData.bank_account : ""}
              onChange={handleInputChange}
            />
          </div>
        </form>

      </div>
      <div className="sticky flex mx-auto bottom-0 pt-4">
        <button
          className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          type="submit"
          onClick={handleFormSubmit}
        >
          Update
        </button>

      </div>
      {feedbackMessage && (
        <div
          className="mt-4 text-center text-sm text-green-500"
          style={{ transition: "opacity 0.35s ease-in" }}
        >
          {feedbackMessage}
        </div>
      )}
      {error && (
        <div
          className="mt-4 text-center text-sm text-red-400"
          style={{ transition: "opacity 0.35s ease-in" }}
        >
          {error}
        </div>
      )}
    </Card>
  );
}
