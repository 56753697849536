const status = [
    {
        value: "",
        label: "SELECT STATUS"
    },
    {
        value: "ACTIVE",
        label: "ACTIVE"
    },
    {
        value: "SUSPENDED",
        label: "SUSPENDED"
    },
    {
        value: "ARCHIVED",
        label: "ARCHIVED"
    },
]

export default status
