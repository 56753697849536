import { deletePlan } from "../../../api/index";

const deletePlanInAdmin = async (x_token, x_user, plan_id) => {
    try {
        return await deletePlan(x_token, x_user, plan_id)
    } catch (error) {
        console.log(error.message)        
    }
}

export default deletePlanInAdmin