import { addPlanFeatures } from "../../../api/plan_features"

const addPlanFeaturesInAdmin = async (x_token, x_user, { name, plan_id, chain_id, tps, note }) => {
    try {
        return await addPlanFeatures(x_token, x_user, { name, plan_id, chain_id, tps, note })
    } catch (error) {
        throw error
    }
}

export default addPlanFeaturesInAdmin