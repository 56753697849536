import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import addPlanInAdmin from '../../../../axios/admin/plans/addPlanInAdmin'
import status from 'views/admin/plans/variables/status'
import frequency from '../variables/frequency'
import TextField from 'components/fields/TextField'


const AddPlan = ({ handleModalClose, fetchData }) => {
    const [error, setError] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        trial_period_days: '',
        grace_days: '',
        status: '',
        notes: ''
    })
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)
    useEffect(() => {
        const fetchDetailsFromSession = () => {
            setXToken(sessionStorage.getItem('x-token'))
            setXUser(sessionStorage.getItem('x-user'))
        }

        fetchDetailsFromSession();
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const validateForm = (formData) => {
        for (let key in formData) {
            if (formData[key] === "") {
                return false;
            }
        }
        return true;
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submit button clicked")
        console.log("Form data:", formData);
        if (!validateForm(formData)) {
            setError("All the fields are mandatory")
            return
        }
        setFeedbackMessage("Waiting for response")
        try {
            const response = await addPlanInAdmin(x_token, x_user, formData)
            console.log("Response in add plan: ", response)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMessage("Plan added successfully")
                fetchData()
                setTimeout(() => {
                    setFormData({
                        name: '',
                        price: '',
                        frequency: '',
                        setup_price: '',
                        trial_period_days: '',
                        grace_days: '',
                        status: '',
                        notes: ''
                    })
                    setFeedbackMessage("")
                    handleModalClose();
                }, 1000)

            } else if (response && response.status === '404') {
                setFeedbackMessage("")
                setError("Validation error, name or rpc_support is duplicate")
            } else {
                setFeedbackMessage("")
                setError("Some error occured, please try again")
            }
        } catch (error) {
            setFeedbackMessage("")
            setError(error.message)
        }

    };

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Add Plan
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Fill in the details below to add a new Plan.
                </p>
            </div>

            <div className="flex-grow overflow-y-auto px-[10px]">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Name*"
                        placeholder="name"
                        id="name"
                        type="text"
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Trial Period Days*"
                        placeholder="123456"
                        id="trial_period_days"
                        type="text"
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Grace Days*"
                        placeholder="123456"
                        id="grace_days"
                        type="text"
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Status*"
                        // placeholder="123456"
                        id="status"
                        type="select"
                        options={status}
                        onChange={handleInputChange}
                    />

                    <TextField
                        variant="admin"
                        extra="mb-3"
                        label="Note*"
                        placeholder="Enter some note"
                        id="notes"
                        type="text"
                        onChange={handleInputChange}
                    />
                </form>
            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0  pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Add
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    )
}

export default AddPlan