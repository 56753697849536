import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProtectedRoute from 'ProtectedRoute';
import { isUserLoggedIn } from 'utils/auth';
import { login } from './redux/features/authSlice';
import Admin from 'layouts/admin';
import Auth from 'layouts/auth';


const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    if (isUserLoggedIn()) {
      const xUser = sessionStorage.getItem('x-user');
      dispatch(login(xUser));
      navigate(currentPath)
    }
  }, [dispatch, location.pathname]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
        {/* Add other routes as needed */}
        <Route path="/" element={<Navigate to="/admin/default" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
