import { useEffect, useState } from "react";
import getAllPaymentsInAdmin from "../../../axios/admin/payments/getAllPaymentsInAdmin";
import { paymentsColumn } from "../customerDetails/variables/columnData";
import GetAllPayments from "./components/GetAllPayments/GetAllPayments";

const Payments = () => {
  const [paymentsData, setPaymentsData] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async () => {
    const response = await getAllPaymentsInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'));
    try {
      console.log("Response is: ", response)
      if (response && response.statusText === "OK") {
        console.log("In response")
        setError("")
        setPaymentsData(response.data);
      } else {
        setError(response.status)
      }
    } catch (error) {
      setError(error.message)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 lg:col-span-12 lg:mb-0">
        <GetAllPayments
          columnsData={paymentsColumn}
          tableData={paymentsData}
          error={error}
          fetchData={fetchData}
        />
      </div>
{/* 
      <FixedPlugin
        buttonIcon={<FaUserPlus className="h-7 w-7 text-white" />}
        modalComponent={<Modal component={<CreateLogins />} />}
      /> */}
    </div>
  );
};

export default Payments;
