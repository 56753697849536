import React, { useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import StatusMenu from "components/status/statusMenu";
import RegisterLogins from "../RegisterLogins";
import Modal from "components/modal/Modal";
import ViewLogin from "./Actions/ViewLogin";
import ChangePassword from "./Actions/ChangePassword";
import ChangePasswordComponent from "../ChangePasswordComponent";
import DeleteCustomer from "./Actions/DeleteCustomer";
import SuspendCustomer from "./Actions/SuspendCustomer";
import DeleteCustomerComponent from "../DeleteCustomerComponent";
import ApproveCustomer from "./Actions/ApproveCustomer";
import SuspendCustomerComponent from "../SuspendCustomerComponent";
import ApproveCustomerComponent from "../ApproveCustomerComponent";
import GetCustomer from "views/admin/customerDetails";
import { IoMdEye } from "react-icons/io";
import getCustomerDetailsInAdmin from "../../../../../axios/admin/customerDetails/getCustomerDetailsInAdmin";
import { useDispatch } from "react-redux";
import { setCustomer } from "../../../../../redux/features/customerSlice";
import { useNavigate } from "react-router-dom";

const ComplexTable = (props) => {
  const { columnsData, tableData, error, fetchData } = props;
  console.log(error);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [apiError, setApiError] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Initial page size (number of rows per page)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const handleViewButtonClick = (lid) => {
    console.log(lid);
    getCustomerDetailsInAdmin(
      lid,
      sessionStorage.getItem('x-token'),
      sessionStorage.getItem('x-user')
    ) 
      .then((response) => {
        if (response && response.statusText === 'OK') {
          console.log("data is: ", response.data[0]);
          dispatch(setCustomer(response.data[0]));
          const cid = response.data[0].cid;
          console.log( response.data[0].contact_name)
          sessionStorage.setItem('contact_name', response.data[0].contact_name)
          console.log("Response in get all logins:", response);
          navigate(`/admin/customer/${cid}`);
        } else {
          setApiError("Some error occurred, try again later.");
        }
      })
      .catch((error) => {
        console.error("Error in get all logins:", error.message);
        setApiError(error.message); // Set error state to display error message
      });
  };


  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Customer Logins List
        </div>
        <CardMenu />
      </div>

      <div className="mt-8 overflow-x-scroll">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "ID") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value === "APPROVED" ? (
                              <MdCheckCircle className="text-green-500" />
                            ) : cell.value === "SUSPENDED" ? (
                              <MdCancel className="text-red-500" />
                            ) : cell.value === "REGISTERED" ? (
                              <MdCancel className="text-brand-500" />
                            ) : cell.value === "CREATED" ? (
                              <MdOutlineError className="text-orange-500" />
                            ) : null}
                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            <StatusMenu status={cell.value} transparent />
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "CREATED AT") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "EMAIL") {
                      data = <p>{cell.value}</p>;
                    } else if (cell.column.Header === "PASSWORD") {
                      data = <p>********</p>;
                    } else if (cell.column.Header === "ACTION") {
                      data = (
                        <div className="mx-auto flex flex-row gap-x-[15px] text-xl">
                          <div className="cursor-pointer text-green-500 hover:text-gray-700">
                            <ChangePassword
                              modalComponent={
                                <Modal
                                  component={
                                    <ChangePasswordComponent
                                      user={row.original}
                                    />
                                  }
                                />
                              }
                            />
                          </div>
                          <div className="cursor-pointer text-red-500 hover:text-gray-700">
                            <DeleteCustomer
                              modalComponent={
                                <Modal
                                  component={
                                    <DeleteCustomerComponent
                                      user={row.original}
                                      fetchData={fetchData}
                                    />
                                  }
                                />
                              }
                            />
                          </div>
                          <div className="cursor-pointer text-blue-600 hover:text-gray-700">
                            {
                              row.original.status === "CREATED" ? (
                                <ViewLogin
                                  modalComponent={
                                    <Modal
                                      component={<RegisterLogins user={row.original} fetchData={fetchData} />
                                      }
                                    />
                                  }
                                />
                              ) : (<IoMdEye onClick={() => handleViewButtonClick(row.original.id)} />)
                            }

                          </div>
                          {(row.original.status === "APPROVED" ||
                            row.original.status === "REGISTERED") && (
                              <div className="cursor-pointer text-red-700 hover:text-gray-700">
                                <SuspendCustomer
                                  modalComponent={
                                    <Modal
                                      component={
                                        <SuspendCustomerComponent
                                          user={row.original}
                                          fetchData={fetchData}
                                        />
                                      }
                                    />
                                  }
                                />
                              </div>
                            )}
                          {row.original.status === "SUSPENDED" && (
                            <div className="cursor-pointer text-green-700 hover:text-gray-700">
                              <ApproveCustomer
                                modalComponent={
                                  <Modal
                                    component={
                                      <ApproveCustomerComponent
                                        user={row.original}
                                        fetchData={fetchData}
                                      />
                                    }
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    }

                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {error && <p className="my-[15px] text-center text-red-600">{error}</p>}
        {apiError && <p className="my-[15px] text-center text-red-600">{apiError}</p>}
      </div>
      {/* Pagination controls */}
      <div className="mt-4 flex justify-end gap-3">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="linear mt-2 rounded-md bg-brand-500 py-2 px-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Previous
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="linear mt-2 rounded-md bg-brand-500 py-2 px-8 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Next
        </button>
        <span className="mx-2 my-auto text-gray-600">
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
      </div>
    </Card>
  );
};

export default ComplexTable;
