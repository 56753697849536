import { columnsDataComplex } from "../plan_features/variables/columnData";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { FaUserPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import GetAllPlanFeatures from "../plan_features/components/GetAllPlanFeatures/GetAllPlanFeatures"
import getAllPlanFeaturesInAdmin from "../../../axios/admin/plan_features/getAllPlanFeaturesInAdmin";
import AddPlanFeatures from "./components/AddPlanFeatures";

const Plan_Features = () => {
    const [planFeaturesData, setPlanFeaturesData] = useState([]);
    const [error, setError] = useState('');

    const fetchData = async () => {
        try {
            const response = await getAllPlanFeaturesInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'))
            if (response.status === 200) {
                console.log("In response")
                setPlanFeaturesData(response.data);
            } else {
                setError(`Error: ${response.status} - ${response.statusText}`);
            }

        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="col-span-5 lg:col-span-12 lg:mb-0">
                <GetAllPlanFeatures
                    columnsData={columnsDataComplex}
                    tableData={planFeaturesData}
                    error={error}
                    fetchData={fetchData}
                />
            </div>
            <FixedPlugin
                buttonIcon={<FaUserPlus className="h-7 w-7 text-white" />}
                modalComponent={<Modal component={<AddPlanFeatures fetchData={fetchData}/>} />}
            />
        </div>
    );
};

export default Plan_Features;
