import { updatePlan } from "../../../api/index";

const updatePlanInAdmin = async (x_token, x_user, plan_id, { name, price, frequency, setup_price, trial_period_days, grace_days, status, notes }) => {
    try {
        return await updatePlan(x_token, x_user, plan_id, { name, price, frequency, setup_price, trial_period_days, grace_days, status, notes })
    } catch (error) {
        throw error
    }
}

export default updatePlanInAdmin