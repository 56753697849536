import { getAdminLogins } from '../../../api/index';

const getAllAdminsInAdmin = async (x_token, x_user) => {
    try {
        return await getAdminLogins(x_token, x_user)
    } catch (error) {
       throw error
    }
}

export default getAllAdminsInAdmin