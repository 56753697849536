export const columnsDataComplex = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "PLAN ID",
        accessor: "plan_id",
    },
    {
        Header: "CHAIN ID",
        accessor: "chain_id",
    },
    {
        Header: "TPS",
        accessor: "tps",
    },
    {
        Header: "NOTES",
        accessor: "note",
    },
    {
        Header: "ACTIONS",
        accessor: "actions",
    }
];