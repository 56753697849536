import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useRef, useState } from 'react'
import TextField from 'components/fields/TextField'
import addPlanFeaturesInAdmin from '../../../../axios/admin/plan_features/addPlanFeaturesInAdmin'
import fetchDetailsFromSession from 'utils/fetchDetailsFromSession'
import { validEmail } from 'utils/emailValidator'
import validateForm from 'utils/validFormData'
import { addPlanFeatureError } from 'errors/error'


const AddPlanFeatures = ({ handleModalClose, fetchData }) => {
    const nameRef = useRef()
    const [error, setError] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [formData, setFormData] = useState({
        "name": "",
        "plan_id": "",
        "chain_id": "",
        "tps": "",
        "note": ""
    })
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)

    // Set focus to name field on component laod
    useEffect(() => {
        nameRef.current.focus();
    }, [])

    // Fetch details from session 
    useEffect(() => {
        const res = fetchDetailsFromSession();
        setXToken(res.x_token);
        setXUser(res.x_user);
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm(formData)) {
            setError("All the fields are mandatory")
            return
        }

        setFeedbackMessage("Waiting for response")
        try {
            const response = await addPlanFeaturesInAdmin(x_token, x_user, formData)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMessage("Plan Feature added successfully")
                fetchData()
                setTimeout(() => {
                    setFormData({
                        "name": "",
                        "plan_id": "",
                        "chain_id": "",
                        "tps": "",
                        "note": ""
                    })
                    setFeedbackMessage("")
                    handleModalClose();
                }, 1000)

            }
        } catch (err) {
            setFeedbackMessage("")
            if (!err?.response) {
                setError("No Server Response, check your console for more details")
            } else if (addPlanFeatureError[err.response?.status]) {
                setError(addPlanFeatureError[err.response.status]);
            } else {
                setError('Failed')
            }
        }

    };

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Add Plan Feature
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Fill in the details below to add a new Plan Feature.
                </p>
            </div>

            <div className="flex-grow overflow-y-auto px-[10px]">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Name*"
                        placeholder="e.g. John Doe"
                        id="name"
                        type="text"
                        ref={nameRef}
                        value={formData.name}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Plan Id*"
                        placeholder="e.g. 1"
                        id="plan_id"
                        type="text"
                        value={formData.plan_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Chain Id*"
                        placeholder="e.g. 1"
                        id="chain_id"
                        type="text"
                        value={formData.chain_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="TPS*"
                        placeholder="e.g. 100"
                        id="tps"
                        type="text"
                        value={formData.tps}
                        onChange={handleInputChange}
                    />

                    <TextField
                        variant="admin"
                        extra="mb-3"
                        label="Note*"
                        placeholder="Enter some note"
                        id="note"
                        type="text"
                        value={formData.note}
                        onChange={handleInputChange}
                    />
                </form>
            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0  pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Add
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    )
}

export default AddPlanFeatures