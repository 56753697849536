import React from "react";

const Address = ({customerDetails}) => {
  return (
    <div className="rounded-lg bg-white bg-clip-border p-4 dark:!bg-navy-800 dark:text-white">
      <div>
        <h2 className="mb-4 text-xl font-semibold text-gray-800 dark:text-white">
          Business Details
        </h2>
        <div>
          <p>
            Entity :{" "}
            {customerDetails.entity!== null
              ? customerDetails.entity
              : "NO DETAILS"}
          </p>
        </div>
        <div>
          <p>
            MSME :{" "}
            {customerDetails.msme !== null
              ? customerDetails.msme
              : "NO DETAILS"}
          </p>
        </div>
        <div>
          <p>
            CIN :{" "}
            {customerDetails.cin !== null ? customerDetails.cin : "NO DETAILS"}
          </p>
        </div>
        <div>
          <p>
            GSTIN :{" "}
            {customerDetails.gstin !== null
              ? customerDetails.gstin
              : "NO DETAILS"}
          </p>
        </div>
        <div>
          <p>
            PAN :{" "}
            {customerDetails.pan !== null ? customerDetails.pan : "NO DETAILS"}
          </p>
        </div>
      </div>

      <div>
      <h2 className="mb-4 text-xl font-semibold text-gray-800 dark:text-white">
        Address Details
      </h2>
      <div className="mb-4">
        <p>{customerDetails.address_1}</p>
        <p>{customerDetails.address_2}</p>
      </div>
    </div>
    </div>
  );
};

export default Address;
