import Card from 'components/card'
import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import addPaymentsInAdmin from '../../../../axios/admin/payments/addPaymentsInAdmin'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TextField from 'components/fields/TextField'
import fetchDetailsFromSession from 'utils/fetchDetailsFromSession'
import validateForm from 'utils/validFormData'
import { addPaymentError } from 'errors/error'

const AddPayment = ({ handleModalClose, fetchData }) => {
    const [error, setError] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [formData, setFormData] = useState({
        "date_of_instrument": "",
        "date_of_payment": "",
        "amount": "",
        "receiving_account": "",
        "receiving_bank": "",
        "reference_id": "",
        "subscription_id": "",
        "invoice_id": "",
        "notes": "",
    })
    const [x_token, setXToken] = useState(null)
    const [x_user, setXUser] = useState(null)
    const admin_user = useSelector((state) => state.auth.user)
    const { cid } = useParams()

    useEffect(() => {
        const res = fetchDetailsFromSession();
        setXToken(res.x_token)
        setXUser(res.x_user)
    }, [])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm(formData)) {
            setError("All the fields are mandatory")
            return
        }
        setFeedbackMessage("Waiting for response")
        try {
            const response = await addPaymentsInAdmin(cid, x_token, x_user, formData, admin_user)
            console.log(response)
            if (response && response.data === "SUCCESS") {
                setError("")
                setFeedbackMessage("Chain added successfully")
                fetchData()
                setTimeout(() => {
                    setFormData({
                        "date_of_instrument": "",
                        "date_of_payment": "",
                        "amount": "",
                        "receiving_account": "",
                        "receiving_bank": "",
                        "reference_id": "",
                        "subscription_id": "",
                        "invoice_id": "",
                        "notes": "",
                    })
                    setFeedbackMessage("")
                    handleModalClose();
                }, 1000)
            }
        } catch (err) {
            setFeedbackMessage("")
            if (!err?.response) {
                setError('No Server Response, check your console for more details');
            } else if (addPaymentError[err.response?.status]) {
                setError(addPaymentError[err.response.status]);
            } else {
                setError('Failed')
            }
        }

    };

    return (
        <Card extra="max-h-[70vh] p-[16px] text-navy-700 bg-cover shadow-none flex flex-col item-center justify-between max-w-[1500px]">
            <div className="mb-4 w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Add Payment
                </h4>
                <p className="mt-2 mb-4 text-base text-gray-600">
                    Fill in the details below to add a new payment.
                </p>
            </div>

            <div className="flex-grow overflow-y-auto">
                <form className="flex flex-col">
                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Date of Instrument*"
                        placeholder="name"
                        id="date_of_instrument"
                        type="text"
                        value={formData.date_of_instrument}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Date of Payment*"
                        placeholder="12345"
                        id="date_of_payment"
                        type="text"
                        value={formData.date_of_payment}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Amount*"
                        placeholder="123456"
                        id="amount"
                        type="text"
                        value={formData.amount}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Receiving Account*"
                        placeholder="123456"
                        id="receiving_account"
                        type="text"
                        value={formData.receiving_account}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Receiving Bank*"
                        placeholder="123456"
                        id="receiving_bank"
                        type="text"
                        value={formData.receiving_bank}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Reference Id*"
                        placeholder="123456"
                        id="reference_id"
                        type="text"
                        value={formData.reference_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Subscription Id*"
                        placeholder="123456"
                        id="subscription_id"
                        type="text"
                        value={formData.subscription_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Invoice Id*"
                        placeholder="123456"
                        id="invoice_id"
                        type="text"
                        value={formData.invoice_id}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Notes*"
                        placeholder="123456"
                        id="notes"
                        type="text"
                        value={formData.notes}
                        onChange={handleInputChange}
                    />

                    <InputField
                        variant="admin"
                        extra="mb-3"
                        label="Admin User*"
                        placeholder="123456"
                        id="admin_user"
                        value={admin_user}
                        type="text"
                        disabled={true}
                    />
                </form>

            </div>
            <div className="sticky flex gap-4 ml-auto bottom-0  pt-4">
                <button
                    className="linear px-6 rounded-xl bg-brand-500 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Add
                </button>
            </div>

            {feedbackMessage && (
                <div
                    className="mt-4 text-center text-sm text-green-500"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {feedbackMessage}
                </div>
            )}
            {error && (
                <div
                    className="mt-4 text-center text-sm text-red-400"
                    style={{ transition: "opacity 0.35s ease-in" }}
                >
                    {error}
                </div>
            )}
        </Card>
    )
}

export default AddPayment