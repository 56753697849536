import React, { useState } from 'react';

function AddButton({ modalComponent, buttonIcon }) {
    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopupState = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div>
            <button
                onClick={handleShowPopupState}
                className="linear mt-2 rounded-full bg-brand-500 p-2 font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 cursor-pointer"
            >
                {buttonIcon}
            </button>
            {showPopup && (
                <div>
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-60 backdrop-filter backdrop-blur-sm z-40">
                        {React.cloneElement(modalComponent, { handleModalClose: handleShowPopupState })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddButton;
