import { suspendCustomer } from "../../../api/index";

const suspendCustomerInAdmin = async  (login_id, x_token, x_user) => {
    try {
        return await suspendCustomer (login_id, x_token, x_user)
    } catch (error) {
        console.log(error.message)
    }
}

export default suspendCustomerInAdmin;