import { getSubscriptionForCustomer } from "../../../api/subscription"

const getSubscriptionForCustomerInAdmin = async (cid, x_token, x_user) => {
  try {
    return await getSubscriptionForCustomer(cid, x_token, x_user)
  } catch (error) {
    console.log(error.message)
  }
}

export default getSubscriptionForCustomerInAdmin