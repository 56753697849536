export const columnsDataComplex = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "TRIAL PERIOD DAYS",
      accessor: "trial_period_days",
    },
    {
      Header: "GRACE DAYS",
      accessor: "grace_days",
    },
    {
      Header: "NOTES",
      accessor: "notes"
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "ACTIONS",
      accessor: "actions",
    },
];