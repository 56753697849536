import { addPlan } from "../../../api/index";

const addPlanInAdmin = async (x_token, x_user, { name, price, frequency, setup_price, trial_period_days, grace_days, status, notes }) => {
    try {
        return await addPlan(x_token, x_user, { name, price, frequency, setup_price, trial_period_days, grace_days, status, notes })
    } catch (error) {
        console.log(error.message)
    }
}

export default addPlanInAdmin