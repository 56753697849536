import { updatePayments } from "../../../api/payments"

const updatePaymentsInAdmin = async (pid, x_token, x_user, { date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes }, admin_user) => {
  try {
    return await updatePayments(pid, x_token, x_user, { date_of_instrument, date_of_payment, amount, receiving_account, receiving_bank, reference_id, subscription_id, invoice_id, notes }, admin_user)
  } catch (error) {
    console.log(error.message)
  }
}

export default updatePaymentsInAdmin