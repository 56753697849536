import React, { useState, useEffect } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { IoMdAdd } from "react-icons/io";


import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { useMemo } from "react";
import Modal from "components/modal/Modal";
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import AddButton from "components/fixedPlugin/addButton";
import AddSubscription from "./AddSubscription";

const SubscriptionTable = (props) => {
    const { columnsData, tableData, error, fetchData } = props;
    console.log("table data is: ", tableData)
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const [feedback, setFeedback] = useState()
    const [contactName, setContactName] = useState()

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            setFeedback("");
        } else {
            setFeedback("Waiting for response");
        }
    }, [tableData]);

    useEffect(() => {
        setContactName(sessionStorage.getItem('contact_name'))
    }, [contactName])

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 }, // Initial page size (number of rows per page)
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex, pageSize },
    } = tableInstance;

    return (
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Subscriptions List
                </div>
                <CardMenu />
            </div>

            <div className="mt-8 overflow-x-scroll ">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                                    >
                                        <p className="text-xs tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </p>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "ID") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "CUSTOMER ID") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "PLAN ID") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "START DATE") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "END DATE") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "PRICE") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "DISCOUNT PERCENT") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "ONE TIME CONSESSION") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "ONE TIME FEE") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "SOLD BY") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "MANAGED BY") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "NOTES") {
                                            data = <p>{cell.value}</p>;
                                        } else if (cell.column.Header === "CREATED AT") {
                                            data = <p>{cell.value}</p>;
                                        }

                                        return (
                                            <td
                                                className="pt-[14px] pb-[18px] sm:text-[14px]"
                                                {...cell.getCellProps()}
                                                key={index}
                                            >
                                                {data}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {error && <p className="my-[15px] text-center text-red-600">{error}</p>}
                {feedback && <p className="my-[15px] text-center text-red-600">{error}</p>}
            </div>
            {/* Pagination controls */}
            <div className="mt-2 flex justify-between items-center">
                <div className="flex gap-3 items-center mx-auto">
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className="linear mt-2 rounded-full bg-brand-500 p-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                        <FaCaretLeft />
                    </button>
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className="linear mt-2 rounded-full bg-brand-500 p-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                        <FaCaretRight />
                    </button>
                    <span className="mt-2 text-gray-600">
                        Page {pageIndex + 1} of {pageOptions.length}
                    </span>
                </div>
                <AddButton buttonIcon={<IoMdAdd className="w-[30px] h-[30px]" />} modalComponent={<Modal component={<AddSubscription fetchData={fetchData} />} />} />
            </div>

        </Card>
    );
};

export default SubscriptionTable;
