export const columnsDataComplex = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "RPC SUPPORT",
        accessor: "rpc_support",
    },
    {
        Header: "NETWORK",
        accessor: "network",
    },
    {
        Header: "VERSION",
        accessor: "version",
    },
    {
        Header: "ACTION",
        accessor: "action",
    }
];