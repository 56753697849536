import { deleteSubscription } from "../../../api/subscription"

const deleteSubscriptionInAdmin = async (cid, sid, x_token, x_user) => {
  try {
    return await deleteSubscription(cid, sid, x_token, x_user)
  } catch (error) {
    console.log(error.message)
  }
}

export default deleteSubscriptionInAdmin