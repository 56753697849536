import { createLogin } from "../../../api/index";

const createCustomerLogin = async (email, password, x_token, x_user ) => {
    try {
        return await createLogin(email, password, x_token, x_user)
    } catch (error) {
       throw error
    }
}

export default createCustomerLogin;