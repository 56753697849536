const countries = [
    {
      "value": "",
      "label": "Select an option"
    },
    {
      "value": "afghanistan",
      "label": "Afghanistan"
    },
    {
      "value": "aland-islands",
      "label": "Aland islands"
    },
    {
      "value": "albania",
      "label": "Albania"
    },
    {
      "value": "algeria",
      "label": "Algeria"
    },
    {
      "value": "american-samoa",
      "label": "American samoa"
    },
    {
      "value": "andorra",
      "label": "Andorra"
    },
    {
      "value": "angola",
      "label": "Angola"
    },
    {
      "value": "anguilla",
      "label": "Anguilla"
    },
    {
      "value": "antarctica",
      "label": "Antarctica"
    },
    {
      "value": "antigua-and-barbuda",
      "label": "Antigua and barbuda"
    },
    {
      "value": "argentina",
      "label": "Argentina"
    },
    {
      "value": "armenia",
      "label": "Armenia"
    },
    {
      "value": "aruba",
      "label": "Aruba"
    },
    {
      "value": "australia",
      "label": "Australia"
    },
    {
      "value": "austria",
      "label": "Austria"
    },
    {
      "value": "azerbaijan",
      "label": "Azerbaijan"
    },
    {
      "value": "bahamas-(the)",
      "label": "Bahamas (the)"
    },
    {
      "value": "bahrain",
      "label": "Bahrain"
    },
    {
      "value": "bangladesh",
      "label": "Bangladesh"
    },
    {
      "value": "barbados",
      "label": "Barbados"
    },
    {
      "value": "belarus",
      "label": "Belarus"
    },
    {
      "value": "belgium",
      "label": "Belgium"
    },
    {
      "value": "belize",
      "label": "Belize"
    },
    {
      "value": "benin",
      "label": "Benin"
    },
    {
      "value": "bermuda",
      "label": "Bermuda"
    },
    {
      "value": "bhutan",
      "label": "Bhutan"
    },
    {
      "value": "bolivia-(plurinational-state-of)",
      "label": "Bolivia (plurinational state of)"
    },
    {
      "value": "bonaire,-sint-eustatius-and-saba",
      "label": "Bonaire, sint eustatius and saba"
    },
    {
      "value": "bosnia-and-herzegovina",
      "label": "Bosnia and herzegovina"
    },
    {
      "value": "botswana",
      "label": "Botswana"
    },
    {
      "value": "bouvet-island",
      "label": "Bouvet island"
    },
    {
      "value": "brazil",
      "label": "Brazil"
    },
    {
      "value": "british-indian-ocean-territory-(the)",
      "label": "British indian ocean territory (the)"
    },
    {
      "value": "brunei-darussalam",
      "label": "Brunei darussalam"
    },
    {
      "value": "bulgaria",
      "label": "Bulgaria"
    },
    {
      "value": "burkina-faso",
      "label": "Burkina faso"
    },
    {
      "value": "burundi",
      "label": "Burundi"
    },
    {
      "value": "cabo-verde",
      "label": "Cabo verde"
    },
    {
      "value": "cambodia",
      "label": "Cambodia"
    },
    {
      "value": "cameroon",
      "label": "Cameroon"
    },
    {
      "value": "canada",
      "label": "Canada"
    },
    {
      "value": "cayman-islands-(the)",
      "label": "Cayman islands (the)"
    },
    {
      "value": "central-african-republic-(the)",
      "label": "Central african republic (the)"
    },
    {
      "value": "chad",
      "label": "Chad"
    },
    {
      "value": "chile",
      "label": "Chile"
    },
    {
      "value": "china",
      "label": "China"
    },
    {
      "value": "christmas-island",
      "label": "Christmas island"
    },
    {
      "value": "cocos-(keeling)-islands-(the)",
      "label": "Cocos (keeling) islands (the)"
    },
    {
      "value": "colombia",
      "label": "Colombia"
    },
    {
      "value": "comoros-(the)",
      "label": "Comoros (the)"
    },
    {
      "value": "congo-(the-democratic-republic-of-the)",
      "label": "Congo (the democratic republic of the)"
    },
    {
      "value": "congo-(the)",
      "label": "Congo (the)"
    },
    {
      "value": "cook-islands-(the)",
      "label": "Cook islands (the)"
    },
    {
      "value": "costa-rica",
      "label": "Costa rica"
    },
    {
      "value": "cote-d'ivoire",
      "label": "Cote d'ivoire"
    },
    {
      "value": "croatia",
      "label": "Croatia"
    },
    {
      "value": "cuba",
      "label": "Cuba"
    },
    {
      "value": "curacao",
      "label": "Curacao"
    },
    {
      "value": "cyprus",
      "label": "Cyprus"
    },
    {
      "value": "czechia",
      "label": "Czechia"
    },
    {
      "value": "denmark",
      "label": "Denmark"
    },
    {
      "value": "djibouti",
      "label": "Djibouti"
    },
    {
      "value": "dominica",
      "label": "Dominica"
    },
    {
      "value": "dominican-republic-(the)",
      "label": "Dominican republic (the)"
    },
    {
      "value": "ecuador",
      "label": "Ecuador"
    },
    {
      "value": "egypt",
      "label": "Egypt"
    },
    {
      "value": "el-salvador",
      "label": "El salvador"
    },
    {
      "value": "equatorial-guinea",
      "label": "Equatorial guinea"
    },
    {
      "value": "eritrea",
      "label": "Eritrea"
    },
    {
      "value": "estonia",
      "label": "Estonia"
    },
    {
      "value": "ethiopia",
      "label": "Ethiopia"
    },
    {
      "value": "falkland-islands-(the)-[malvinas]",
      "label": "Falkland islands (the) [malvinas]"
    },
    {
      "value": "faroe-islands-(the)",
      "label": "Faroe islands (the)"
    },
    {
      "value": "fiji",
      "label": "Fiji"
    },
    {
      "value": "finland",
      "label": "Finland"
    },
    {
      "value": "france",
      "label": "France"
    },
    {
      "value": "french-guiana",
      "label": "French guiana"
    },
    {
      "value": "french-polynesia",
      "label": "French polynesia"
    },
    {
      "value": "french-southern-territories-(the)",
      "label": "French southern territories (the)"
    },
    {
      "value": "gabon",
      "label": "Gabon"
    },
    {
      "value": "gambia-(the)",
      "label": "Gambia (the)"
    },
    {
      "value": "georgia",
      "label": "Georgia"
    },
    {
      "value": "germany",
      "label": "Germany"
    },
    {
      "value": "ghana",
      "label": "Ghana"
    },
    {
      "value": "gibraltar",
      "label": "Gibraltar"
    },
    {
      "value": "greece",
      "label": "Greece"
    },
    {
      "value": "greenland",
      "label": "Greenland"
    },
    {
      "value": "grenada",
      "label": "Grenada"
    },
    {
      "value": "guadeloupe",
      "label": "Guadeloupe"
    },
    {
      "value": "guam",
      "label": "Guam"
    },
    {
      "value": "guatemala",
      "label": "Guatemala"
    },
    {
      "value": "guernsey",
      "label": "Guernsey"
    },
    {
      "value": "guinea",
      "label": "Guinea"
    },
    {
      "value": "guinea-bissau",
      "label": "Guinea-bissau"
    },
    {
      "value": "guyana",
      "label": "Guyana"
    },
    {
      "value": "haiti",
      "label": "Haiti"
    },
    {
      "value": "heard-island-and-mcdonald-islands",
      "label": "Heard island and mcdonald islands"
    },
    {
      "value": "holy-see-(the)",
      "label": "Holy see (the)"
    },
    {
      "value": "honduras",
      "label": "Honduras"
    },
    {
      "value": "hong-kong",
      "label": "Hong kong"
    },
    {
      "value": "hungary",
      "label": "Hungary"
    },
    {
      "value": "iceland",
      "label": "Iceland"
    },
    {
      "value": "india",
      "label": "India"
    },
    {
      "value": "indonesia",
      "label": "Indonesia"
    },
    {
      "value": "iran-(islamic-republic-of)",
      "label": "Iran (islamic republic of)"
    },
    {
      "value": "iraq",
      "label": "Iraq"
    },
    {
      "value": "ireland",
      "label": "Ireland"
    },
    {
      "value": "isle-of-man",
      "label": "Isle of man"
    },
    {
      "value": "israel",
      "label": "Israel"
    },
    {
      "value": "italy",
      "label": "Italy"
    },
    {
      "value": "jamaica",
      "label": "Jamaica"
    },
    {
      "value": "japan",
      "label": "Japan"
    },
    {
      "value": "jersey",
      "label": "Jersey"
    },
    {
      "value": "jordan",
      "label": "Jordan"
    },
    {
      "value": "kazakhstan",
      "label": "Kazakhstan"
    },
    {
      "value": "kenya",
      "label": "Kenya"
    },
    {
      "value": "kiribati",
      "label": "Kiribati"
    },
    {
      "value": "korea-(the-democratic-people's-republic-of)",
      "label": "Korea (the democratic people's republic of)"
    },
    {
      "value": "korea-(the-republic-of)",
      "label": "Korea (the republic of)"
    },
    {
      "value": "kuwait",
      "label": "Kuwait"
    },
    {
      "value": "kyrgyzstan",
      "label": "Kyrgyzstan"
    },
    {
      "value": "lao-people's-democratic-republic-(the)",
      "label": "Lao people's democratic republic (the)"
    },
    {
      "value": "latvia",
      "label": "Latvia"
    },
    {
      "value": "lebanon",
      "label": "Lebanon"
    },
    {
      "value": "lesotho",
      "label": "Lesotho"
    },
    {
      "value": "liberia",
      "label": "Liberia"
    },
    {
      "value": "libya",
      "label": "Libya"
    },
    {
      "value": "liechtenstein",
      "label": "Liechtenstein"
    },
    {
      "value": "lithuania",
      "label": "Lithuania"
    },
    {
      "value": "luxembourg",
      "label": "Luxembourg"
    },
    {
      "value": "macao",
      "label": "Macao"
    },
    {
      "value": "macedonia-(the-former-yugoslav-republic-of)",
      "label": "Macedonia (the former yugoslav republic of)"
    },
    {
      "value": "madagascar",
      "label": "Madagascar"
    },
    {
      "value": "malawi",
      "label": "Malawi"
    },
    {
      "value": "malaysia",
      "label": "Malaysia"
    },
    {
      "value": "maldives",
      "label": "Maldives"
    },
    {
      "value": "mali",
      "label": "Mali"
    },
    {
      "value": "malta",
      "label": "Malta"
    },
    {
      "value": "marshall-islands-(the)",
      "label": "Marshall islands (the)"
    },
    {
      "value": "martinique",
      "label": "Martinique"
    },
    {
      "value": "mauritania",
      "label": "Mauritania"
    },
    {
      "value": "mauritius",
      "label": "Mauritius"
    },
    {
      "value": "mayotte",
      "label": "Mayotte"
    },
    {
      "value": "mexico",
      "label": "Mexico"
    },
    {
      "value": "micronesia-(federated-states-of)",
      "label": "Micronesia (federated states of)"
    },
    {
      "value": "moldova-(the-republic-of)",
      "label": "Moldova (the republic of)"
    },
    {
      "value": "monaco",
      "label": "Monaco"
    },
    {
      "value": "mongolia",
      "label": "Mongolia"
    },
    {
      "value": "montenegro",
      "label": "Montenegro"
    },
    {
      "value": "montserrat",
      "label": "Montserrat"
    },
    {
      "value": "morocco",
      "label": "Morocco"
    },
    {
      "value": "mozambique",
      "label": "Mozambique"
    },
    {
      "value": "myanmar",
      "label": "Myanmar"
    },
    {
      "value": "namibia",
      "label": "Namibia"
    },
    {
      "value": "nauru",
      "label": "Nauru"
    },
    {
      "value": "nepal",
      "label": "Nepal"
    },
    {
      "value": "netherlands-(the)",
      "label": "Netherlands (the)"
    },
    {
      "value": "new-caledonia",
      "label": "New caledonia"
    },
    {
      "value": "new-zealand",
      "label": "New zealand"
    },
    {
      "value": "nicaragua",
      "label": "Nicaragua"
    },
    {
      "value": "niger-(the)",
      "label": "Niger (the)"
    },
    {
      "value": "nigeria",
      "label": "Nigeria"
    },
    {
      "value": "niue",
      "label": "Niue"
    },
    {
      "value": "norfolk-island",
      "label": "Norfolk island"
    },
    {
      "value": "northern-mariana-islands-(the)",
      "label": "Northern mariana islands (the)"
    },
    {
      "value": "norway",
      "label": "Norway"
    },
    {
      "value": "oman",
      "label": "Oman"
    },
    {
      "value": "pakistan",
      "label": "Pakistan"
    },
    {
      "value": "palau",
      "label": "Palau"
    },
    {
      "value": "palestine,-state-of",
      "label": "Palestine, state of"
    },
    {
      "value": "panama",
      "label": "Panama"
    },
    {
      "value": "papua-new-guinea",
      "label": "Papua new guinea"
    },
    {
      "value": "paraguay",
      "label": "Paraguay"
    },
    {
      "value": "peru",
      "label": "Peru"
    },
    {
      "value": "philippines-(the)",
      "label": "Philippines (the)"
    },
    {
      "value": "pitcairn",
      "label": "Pitcairn"
    },
    {
      "value": "poland",
      "label": "Poland"
    },
    {
      "value": "portugal",
      "label": "Portugal"
    },
    {
      "value": "puerto-rico",
      "label": "Puerto rico"
    },
    {
      "value": "qatar",
      "label": "Qatar"
    },
    {
      "value": "reunion",
      "label": "Reunion"
    },
    {
      "value": "romania",
      "label": "Romania"
    },
    {
      "value": "russian-federation-(the)",
      "label": "Russian federation (the)"
    },
    {
      "value": "rwanda",
      "label": "Rwanda"
    },
    {
      "value": "saint-barthelemy",
      "label": "Saint barthelemy"
    },
    {
      "value": "saint-helena,-ascension-and-tristan-da-cunha",
      "label": "Saint helena, ascension and tristan da cunha"
    },
    {
      "value": "saint-kitts-and-nevis",
      "label": "Saint kitts and nevis"
    },
    {
      "value": "saint-lucia",
      "label": "Saint lucia"
    },
    {
      "value": "saint-martin-(french-part)",
      "label": "Saint martin (french part)"
    },
    {
      "value": "saint-pierre-and-miquelon",
      "label": "Saint pierre and miquelon"
    },
    {
      "value": "saint-vincent-and-the-grenadines",
      "label": "Saint vincent and the grenadines"
    },
    {
      "value": "samoa",
      "label": "Samoa"
    },
    {
      "value": "san-marino",
      "label": "San marino"
    },
    {
      "value": "sao-tome-and-principe",
      "label": "Sao tome and principe"
    },
    {
      "value": "saudi-arabia",
      "label": "Saudi arabia"
    },
    {
      "value": "senegal",
      "label": "Senegal"
    },
    {
      "value": "serbia",
      "label": "Serbia"
    },
    {
      "value": "seychelles",
      "label": "Seychelles"
    },
    {
      "value": "sierra-leone",
      "label": "Sierra leone"
    },
    {
      "value": "singapore",
      "label": "Singapore"
    },
    {
      "value": "sint-maarten-(dutch-part)",
      "label": "Sint maarten (dutch part)"
    },
    {
      "value": "slovakia",
      "label": "Slovakia"
    },
    {
      "value": "slovenia",
      "label": "Slovenia"
    },
    {
      "value": "solomon-islands",
      "label": "Solomon islands"
    },
    {
      "value": "somalia",
      "label": "Somalia"
    },
    {
      "value": "south-africa",
      "label": "South africa"
    },
    {
      "value": "south-georgia-and-the-south-sandwich-islands",
      "label": "South georgia and the south sandwich islands"
    },
    {
      "value": "south-sudan",
      "label": "South sudan"
    },
    {
      "value": "spain",
      "label": "Spain"
    },
    {
      "value": "sri-lanka",
      "label": "Sri lanka"
    },
    {
      "value": "sudan-(the)",
      "label": "Sudan (the)"
    },
    {
      "value": "suriname",
      "label": "Suriname"
    },
    {
      "value": "svalbard-and-jan-mayen",
      "label": "Svalbard and jan mayen"
    },
    {
      "value": "swaziland",
      "label": "Swaziland"
    },
    {
      "value": "sweden",
      "label": "Sweden"
    },
    {
      "value": "switzerland",
      "label": "Switzerland"
    },
    {
      "value": "syrian-arab-republic",
      "label": "Syrian arab republic"
    },
    {
      "value": "taiwan-(province-of-china)",
      "label": "Taiwan (province of china)"
    },
    {
      "value": "tajikistan",
      "label": "Tajikistan"
    },
    {
      "value": "tanzania,-united-republic-of",
      "label": "Tanzania, united republic of"
    },
    {
      "value": "thailand",
      "label": "Thailand"
    },
    {
      "value": "timor-leste",
      "label": "Timor-leste"
    },
    {
      "value": "togo",
      "label": "Togo"
    },
    {
      "value": "tokelau",
      "label": "Tokelau"
    },
    {
      "value": "tonga",
      "label": "Tonga"
    },
    {
      "value": "trinidad-and-tobago",
      "label": "Trinidad and tobago"
    },
    {
      "value": "tunisia",
      "label": "Tunisia"
    },
    {
      "value": "turkey",
      "label": "Turkey"
    },
    {
      "value": "turkmenistan",
      "label": "Turkmenistan"
    },
    {
      "value": "turks-and-caicos-islands-(the)",
      "label": "Turks and caicos islands (the)"
    },
    {
      "value": "tuvalu",
      "label": "Tuvalu"
    },
    {
      "value": "uganda",
      "label": "Uganda"
    },
    {
      "value": "ukraine",
      "label": "Ukraine"
    },
    {
      "value": "united-arab-emirates-(the)",
      "label": "United arab emirates (the)"
    },
    {
      "value": "united-kingdom-of-great-britain-and-northern-ireland-(the)",
      "label": "United kingdom of great britain and northern ireland (the)"
    },
    {
      "value": "united-states-minor-outlying-islands-(the)",
      "label": "United states minor outlying islands (the)"
    },
    {
      "value": "united-states-of-america-(the)",
      "label": "United states of america (the)"
    },
    {
      "value": "uruguay",
      "label": "Uruguay"
    },
    {
      "value": "uzbekistan",
      "label": "Uzbekistan"
    },
    {
      "value": "vanuatu",
      "label": "Vanuatu"
    },
    {
      "value": "venezuela-(bolivarian-republic-of)",
      "label": "Venezuela (bolivarian republic of)"
    },
    {
      "value": "viet-nam",
      "label": "Viet nam"
    },
    {
      "value": "virgin-islands-(british)",
      "label": "Virgin islands (british)"
    },
    {
      "value": "virgin-islands-(u.s.)",
      "label": "Virgin islands (u.s.)"
    },
    {
      "value": "wallis-and-futuna",
      "label": "Wallis and futuna"
    },
    {
      "value": "western-sahara*",
      "label": "Western sahara*"
    },
    {
      "value": "yemen",
      "label": "Yemen"
    },
    {
      "value": "zambia",
      "label": "Zambia"
    },
    {
      "value": "zimbabwe",
      "label": "Zimbabwe"
    }
  ];
  
export default countries;