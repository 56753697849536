import React, { useState } from 'react'
import Card from 'components/card'

const DeleteCustomerComponent = ({ user, fetchData, handleModalClose }) => {
    const [feedbackMessage, setFeedbackMesage] = useState("")
    const [isDeleted, setIsDeleted] = useState(false)
    const handleButtonClick = () => {
        setFeedbackMesage("Customer deleted successfully")
        setIsDeleted(true)
        fetchData()
        setInterval(() => {
            setFeedbackMesage("")
            handleModalClose()
        }, 1000)
    }
    return (
        <Card extra={"items-center w-full h-full p-[16px] text-navy-700 bg-cover shadow-none max-w-[400px] fixed inset-0 flex items-center justify-center z-50"}>
            <div className='flex flex-col gap-y-[20px]'>
                <div>
                    <p>Are you sure you want to delete customer with email <span className='text-brand-600'>{user.email}</span></p>
                </div>
                {feedbackMessage && (
                    <div
                        className="text-center text-sm text-green-500"
                        style={{ transition: "opacity 0.35s ease-in" }}
                    >
                        {feedbackMessage}
                    </div>

                )}
                <button
                    className={`linear mt-2 w-full rounded-xl bg-red-500 py-[12px] text-base font-medium text-white transition duration-700 hover:bg-red-600 active:bg-red-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-600 dark:active:bg-red-700`}
                    type="button"
                    onClick={handleButtonClick}
                    disabled={isDeleted}
                >
                    Delete
                </button>

            </div>


        </Card>
    )
}

export default DeleteCustomerComponent