import React, { useState } from 'react'
import { IoMdEye } from "react-icons/io";

const ViewSubscription = ({ modalComponent }) => {
    const [showState, setShowState] = useState(false)
    const handleShowPopupState = () => {
        setShowState(!showState)
    }
    return (
        <>
            <IoMdEye onClick={() => setShowState(true)}/>
            {showState && (
                <div>
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-60 backdrop-filter backdrop-blur-sm z-40">
                        {React.cloneElement(modalComponent, { handleModalClose: handleShowPopupState })}
                    </div>
                </div>
            )}
        </>

    )
}

export default ViewSubscription