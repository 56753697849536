import { deletePlanFeature } from "../../../api/plan_features";

const deletePlanFeatureInAdmin = async (x_token, x_user, pf_id) => {
    try {
        return await deletePlanFeature(x_token, x_user, pf_id)
    } catch (error) {
        console.log(error)        
    }
}

export default deletePlanFeatureInAdmin