import InputField from "components/fields/InputField";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import createCustomerLogin from "../../../../axios/admin/customer/createCustomerLogin";
import { validEmail } from "utils/emailValidator";
import { validPassword } from "utils/passwordValidator";
import { createLoginErrors } from '../../../../errors/error'

export default function CreateLogins() {
  const userRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [matchPwd, setMatchPwd] = useState("")

  const [validPwd, setValidPwd] = useState(false)
  const [validUser, setValidUser] = useState(false)
  const [validMatch, setValidMatch] = useState(false)

  // focus on user name input on load.
  useEffect(() => {
    userRef.current.focus();
  }, [])

  // Checks whether user name is vlaid or not
  useEffect(() => {
    setValidUser(validEmail(user));
  }, [user])

  // Checks whether password is valid or not and password, match password fields matches or not
  useEffect(() => {
    setValidPwd(validPassword(pwd));
    setValidMatch(pwd === matchPwd)
  }, [pwd, matchPwd])

  // sets error to empty as user has read error and now taking action on it.
  useEffect(() => {
    setError('')
  }, [user, pwd, matchPwd])

  const [detailsFromSession, setDetailsFromSession] = useState({
    x_token: "",
    x_user: "",
  });
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getDetailsFromSession = () => {
      const x_token = sessionStorage.getItem("x-token");
      const x_user = sessionStorage.getItem("x-user");
      setDetailsFromSession({
        x_token,
        x_user,
      });
    };
    getDetailsFromSession();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!user || !pwd) {
      setError("Email and password required");
      return;
    }

    try {
      const response = await createCustomerLogin(
        user,
        pwd,
        detailsFromSession.x_token,
        detailsFromSession.x_user
      );

      if (response.data === "SUCCESS") {
        setFeedbackMessage("Login created successfully");
        setUser("");
        setPwd("");
      }

    } catch (err) {
      
      setFeedbackMessage("")
      if (!err?.response) {
        setError('No Server Response, check your console for more details');
      } else if (createLoginErrors[err.response?.status]) {
        setError(createLoginErrors[err.response.status]);
      } else {
        setError('Failed')
      }
    }
  };

  return (
    <section>
      <Card extra={"items-center w-full h-full p-[16px] bg-cover shadow-none max-w-[400px] fixed inset-0 flex items-center justify-center z-50"}>
        <div className="mb-8 w-full">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Create Customers
          </h4>
          <p className="mt-2 text-base text-gray-600">
            Add your new customers from here
          </p>
        </div>
        <div className=" flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:items-center lg:justify-start">
          <div className=" w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
            <form onSubmit={handleFormSubmit}>
              {/* Email */}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email*"
                placeholder="mail@simmmple.com"
                id="user"
                type="email"
                ref={userRef}
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
              {
                !validUser && <p id="user" className="text-red-600 text-[0.8rem] mb-3">Email should contain '@' symbol followed by valid domain name.</p>
              }


              {/* Password */}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                showIcon="true"
              />
              {
                !validPwd && <p id="password" className="text-red-600 text-[0.8rem] mb-3">Password should have at least 8 characters, 1 capital letter, 1 digit and 1 special character</p>
              }

              {/* Confirm Password */}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Confirm Password*"
                placeholder="Min. 8 characters"
                id="confirm_password"
                type="password"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
              />
              {
                !validMatch && <p id="confirm_password" className="text-red-600 text-[0.8rem] mb-3">Password is not matching</p>
              }

              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                type="submit"
              >
                Create Customer
              </button>
            </form>
            {feedbackMessage !== null && feedbackMessage !== "" && (
              <div
                className="mt-4 text-center text-sm text-green-500"
                style={{ transition: "opacity 0.35s ease-in" }}
              >
                {feedbackMessage}
              </div>
            )}
            {error !== null && error !== "" && (
              <div
                className="mt-4 text-center text-sm text-red-400"
                style={{ transition: "opacity 0.35s ease-in" }}
              >
                {error}
              </div>
            )}
          </div>
        </div>
      </Card>
    </section>
  );
}
