import { getCustomerWithlid } from '../../../api/customerDetails';

const getCustomerDetailsInAdmin = async (lid, x_token, x_user) => {
    try {
        console.log("In api call")
        return await getCustomerWithlid(lid, x_token, x_user)
    } catch (error) {
        console.log(error.response.data.message || "Failed to fetch data")
    }
}

export default getCustomerDetailsInAdmin