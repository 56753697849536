export const currencyOptions = [
    {
        "value": "",
        "label": "Select an option"
    },
    {
        "value": "INR",
        "label": "INR"
    },
    {
        "value": "USD",
        "label": "USD"
    },
]