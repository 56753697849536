import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Chain from "views/admin/chain";
import DataTables from "views/admin/tables";
import Customer from "views/admin/customer/index";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import { Plan } from "views/admin/plans";
import Plan_Features from "./views/admin/plan_features/index"
import { GiBreakingChain } from "react-icons/gi";
import GetCustomer from "views/admin/customerDetails";
import Payments from "views/admin/payments";
import Subscriptions from "views/admin/subscription";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Chain",
    layout: "/admin",
    path: "chain",
    icon: <GiBreakingChain className="h-6 w-6" />,
    component: <Chain />,
  },
  {
    name: "Customer",
    layout: "/admin",
    path: "customer",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Customer />
  },

  {
    name: "Customer",
    layout: "/admin",
    path: "customer/:cid",
    icon: <MdPerson className="h-6 w-6" />,
    component: <GetCustomer />,
    hidden: true
  },

  {
    name: "Plan",
    layout: "/admin",
    path: "plan",
    icon: <MdLock className="h-6 w-6" />,
    component: <Plan />
  },

  {
    name: "Plan Features",
    layout: "/admin",
    path: "pFeatures",
    icon: <MdLock className="h-6 w-6" />,
    component: <Plan_Features />
  },

  {
    name: "Payments",
    layout: "/admin",
    path: "payments",
    icon: <MdLock className="h-6 w-6" />,
    component: <Payments />
  },

  {
    name: "Subscriptions",
    layout: "/admin",
    path: "subscriptions",
    icon: <MdLock className="h-6 w-6" />,
    component: <Subscriptions />
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />
  }
];


export default routes;
