import { updateSubscription } from "../../../api/subscription"

const updateSubscriptionInAdmin = async (cid, sid, x_token, x_user, new_end_datetime) => {
  console.log("new end date: ". new_end_date)
  try {
    return await updateSubscription(cid, sid, x_token, x_user, new_end_datetime)
  } catch (error) {
    console.log(error.message)
  }
}

export default updateSubscriptionInAdmin