export const hours = [
    { value: "", label: "HH" },
    ...Array.from({ length: 24 }, (_, i) => (
        {
            value: String(i).padStart(2, '0'),
            label: String(i).padStart(2, '0')
        }
    ))
];

export const minutes = [
    { value: "", label: "MM" },
    ...Array.from({ length: 60 }, (_, i) => (
        {
            value: String(i).padStart(2, '0'),
            label: String(i).padStart(2, '0')
        }
    ))
];

export const seconds = [
    { value: "", label: "SS" },
    ...Array.from({ length: 60 }, (_, i) => (
        {
            value: String(i).padStart(2, '0'),
            label: String(i).padStart(2, '0')
        }
    ))
];
