import InputField from "components/fields/InputField";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import createCustomerLogin from "../../../../axios/admin/customer/createCustomerLogin";
import { validEmail } from "utils/emailValidator";
import { validPassword } from "utils/passwordValidator";

export default function ChangePasswordComponent({ user }) {
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [error, setError] = useState(null);


  const handlePasswordwordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassworrdChange = (e) => {
    setConfirmPass(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!confirmPass || !password) {
      setError("Password and Confirm Password can't be empty");
      return;
    }

    if(!validPassword(password)) {
      setError("Password should have at least 8 characters, 1 capital letter and 1 digit")
      return;
    }

    if(password !== confirmPass) {
        setError("Password does not match")
        return
    }

    setFeedbackMessage("Password updated successfully")
    setInterval(() => {
      setFeedbackMessage("")
    }, 3000)
    setInterval(() => {
      setError("")
    }, 3000)
  };

  return (
    <Card extra={"items-center w-full h-full text-navy-700 p-[16px] bg-cover shadow-none max-w-[400px] fixed inset-0 flex items-center justify-center z-50"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Create Customers
        </h4>
        <p className="mt-2 text-base text-gray-600">
          Add your new customers from here
        </p>
      </div>
      <div className=" flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:items-center lg:justify-start">
        <div className=" w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
          <form onSubmit={handleFormSubmit}>
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder={user.email}
              id="email"
              type="email"
              disabled
            />

            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Min. 8 characters"
              id="password"
              type={"password"}
              onChange={handlePasswordwordChange}
              showIcon="true"
            />

            {/* Confirm Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Confirm Password*"
              placeholder="Min. 8 characters"
              id="confirm_pass"
              type={"password"}
              onChange={handleConfirmPassworrdChange}
              // showIcon={false}
            />

            {feedbackMessage !== null && feedbackMessage !== "" && (
              <div
                className="mt-4 text-center text-sm text-green-500"
                style={{ transition: "opacity 0.35s ease-in" }}
              >
                {feedbackMessage}
              </div>
            )}
            {error !== null && error !== "" && (
              <div
                className="mt-4 text-center text-sm text-red-400"
                style={{ transition: "opacity 0.35s ease-in" }}
              >
                {error}
              </div>
            )}
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </Card>
  );
}
