import GetAllChain from './GetAllChain/GetAllChain'
import { columnsDataComplex } from "../chain/variables/columnData";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { FaUserPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import AddChain from "./component/AddChain";
import Modal from "components/modal/Modal";
import getAllChainInAdmin from '../../../axios/admin/chain/getAllChainInAdmin';
import { getChainErrors } from 'errors/error';

const Chain = () => {
    const [chainData, setChainData] = useState([]);
    const [error, setError] = useState('');

    const fetchData = async () => {
        try {
            const response = await getAllChainInAdmin(sessionStorage.getItem('x-token'), sessionStorage.getItem('x-user'))
            if (response.status === 200) {
                setError("")
                setChainData(response.data);
            }
        } catch (err) {
            if (!err?.response) {
                setError("No Server Response, check your console for more details")
            } else if (getChainErrors[err.response?.status]) {
                setError(getChainErrors[err.response.status]);
            } else {
                setError('Failed')
            }
        }
    };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="col-span-5 lg:col-span-12 lg:mb-0">
                <GetAllChain
                    columnsData={columnsDataComplex}
                    tableData={chainData}
                    error={error}
                    fetchData={fetchData}
                />
            </div>
            <FixedPlugin
                buttonIcon={<FaUserPlus className="h-7 w-7 text-white" />}
                modalComponent={<Modal component={<AddChain fetchData={fetchData} />} />}
            />
        </div>
    );
};

export default Chain;
